import React from "react";
import classNames from "classnames";
import { useField, FieldHookConfig } from "formik";
import "./style.scss";
type Props = FieldHookConfig<boolean> & {
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
  id?: string;
};
export const FormCheckbox: React.FC<Props> = ({
  className,
  children,
  disabled = false,
  id = "",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(event.target.checked);
  };
  return (
    <div className={classNames(className)}>
      <label className="form-checkbox">
        {children}
        <input
          type="checkbox"
          checked={field.value}
          onChange={handleChange}
          disabled={disabled}
          id={id}
        />
        <span></span>
      </label>
      {meta.touched && meta.error && (
        <div className="form-checkbox__validation">{meta.error}</div>
      )}
    </div>
  );
};
