import { postAuditRequest } from "@/api";
import { Button, FormInput, Modal } from "@/components";
import { FormTextArea } from "@/components/FormTextArea";
import { useAuth } from "@/contexts";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import "./style.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Valid email is required")
    .required("Email is required"),
  name: Yup.string().required("Name is required"),
  projectName: Yup.string().required("Project name is required"),
  jobTitle: Yup.string().required("Job title is required"),
  desiredDate: Yup.string().required("Desired date is required"),
  country: Yup.string().required("Country is required"),
  alternamtiveContact: Yup.string(),
  addionalInformation: Yup.string(),
});

interface Props {
  open: boolean;
  setOpen(param: boolean): void;
}

export const QuoteModal = ({ open, setOpen }: Props) => {
  const { userInfo } = useAuth();
  const [error, setError] = useState("");
  const [sent, setSent] = useState<boolean>(false);
  const reset = () => setSent(false);

  return (
    <Modal
      title="Get a Quote for sec3 Launch Audit"
      open={open}
      setOpen={(param) => {
        setOpen(param);
        reset();
      }}
    >
      {sent ? (
        <div className="audit-quote-modal">
          <div>
            Success! Our team has received your quote request. We will get back
            to you as soon as possible.
          </div>
          <Button
            className="ms-auto"
            onClick={() => {
              setOpen(false);
            }}
          >
            OK
          </Button>
        </div>
      ) : (
        <div className="audit-quote-modal">
          <div>
            Please fill out the form below and a member of sec3 team will get
            back to you soon.
          </div>
          <div className="d-flex mt-4">
            <Formik
              initialValues={{
                name: userInfo?.username || "",
                email: userInfo?.email || "",
                projectName: "",
                jobTitle: "",
                desiredDate: "",
                country: "",
                alternativeContact: "",
                additionalInformation: "",
              }}
              validationSchema={validationSchema}
              onSubmit={async (values, { resetForm }) => {
                setError("");
                try {
                  await postAuditRequest(values);
                  setSent(true);
                  resetForm();
                } catch (error: any) {
                  setError(error?.response?.data?.message || error.message);
                }
              }}
            >
              {({ isSubmitting }) => {
                return (
                  <Form>
                    <div className="d-flex overflow-hidden">
                      <div className="row">
                        <div className="col-md-6">
                          <FormInput
                            label="Name*"
                            name="name"
                            isRequired
                            placeholder="John Doe"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            label="Email*"
                            name="email"
                            isRequired
                            placeholder="john@youremail.so"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            label="Project Name*"
                            name="projectName"
                            isRequired
                            placeholder="Your Solana Project"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            label="Job Title*"
                            name="jobTitle"
                            isRequired
                            placeholder="CEO"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            label="Desired Audit Complete Date*"
                            name="desiredDate"
                            isRequired
                            placeholder="MM-DD-YYYY"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            label="Country*"
                            name="country"
                            isRequired
                            placeholder="United States"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-md-6">
                          <FormInput
                            label="Alternative Contact"
                            name="alternativeContact"
                            placeholder="Telegram"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                        <div className="col-12">
                          <FormTextArea
                            label="Additional Information"
                            name="additionalInformation"
                            placeholder="Please tell us more about your project"
                            className="mt-2 mt-sm-3"
                          />
                        </div>
                      </div>
                    </div>
                    {error && (
                      <div className="text-left mt-3 color-red">{error}</div>
                    )}
                    <div className="mt-3 d-flex justify-content-end">
                      <Button
                        color="light"
                        onClick={() => {
                          setOpen(false);
                        }}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="ms-3"
                        type="submit"
                        isLoading={isSubmitting}
                      >
                        Submit
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      )}
    </Modal>
  );
};
