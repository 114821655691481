/* eslint-disable react-hooks/exhaustive-deps */
import { forgotPassword } from "@/api";
import { Box, Button, FormInput } from "@/components";
import { OnboardingLayout } from "@/layouts";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Valid email is required")
    .required("Email is required"),
});

export const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }
    return () => {};
  }, [isSuccess]);

  return (
    <OnboardingLayout>
      <div className="onboarding-title">
        <h3>Password recovery</h3>
      </div>
      <Box>
        {isSuccess ? (
          <p>
            We've sent an email to your email. Please check your email and reset
            password.
          </p>
        ) : (
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                await forgotPassword(values.email);
                setIsSuccess(true);
              } catch (error: any) {
                setError(error.response.data.message);
              }
            }}
          >
            {({ isSubmitting }) => {
              return (
                <div>
                  <Form>
                    <FormInput
                      label="Email"
                      name="email"
                      isRequired
                      placeholder="admin@mycompany.com"
                    />

                    {error && (
                      <div className="text-left mt-3 color-red">{error}</div>
                    )}
                    <div className="mt-3">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-100"
                        isLoading={isSubmitting}
                      >
                        Send reset link
                      </Button>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
      </Box>
    </OnboardingLayout>
  );
};
