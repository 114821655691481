/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getSves } from "@/api";
import { Rule } from "@/types";
import React, { createContext, useContext, useEffect, useState } from "react";
import { useLocalstorageState } from "rooks";
export interface SveState {
  sveData: { [key: string]: Rule };
}

const initialState: SveState = {
  sveData: {},
};

export const SveContext = createContext<SveState>(initialState);

export const useSve = () => useContext(SveContext);

const STORAGE_KEY = "sec3-sve-state";

interface Props {
  children: React.ReactNode;
}

export const SveProvider = ({ children }: Props) => {
  const [_, set] = useLocalstorageState(STORAGE_KEY, initialState);
  const [sveData, setSveData] = useState<{ [key: string]: Rule }>({});
  useEffect(() => {
    set({ sveData });
    return () => {};
  }, [sveData, set]);

  const init = async () => {
    const res = await getSves();
    setSveData(res.reduce((a: any, v: Rule) => ({ ...a, [v.id]: v }), {}));
  };
  useEffect(() => {
    init();
    return () => {};
  }, []);

  return (
    <SveContext.Provider value={{ sveData }}>{children}</SveContext.Provider>
  );
};
