/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { addInsightsWidget, updateInsightsWidget } from "@/api";
import {
  AddressSelect,
  Button,
  ColorThemeSelect,
  FigureGraph,
  Input,
  Select1,
  SidePanel,
  Switch,
} from "@/components";
import {
  FIGURE_CHART_TYPE_DATA,
  FIGURE_INTERVAL_DATA,
  FIGURE_TIME_RANGE_DATA,
  INSIGHT_LAYOUT_COL_NUM,
} from "@/config";
import { useMonitor } from "@/contexts";
import { useInput } from "@/hooks";
import { InsightWidget } from "@/types";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { SelectZone } from "./components";

import "./style.scss";

interface Props {
  open: boolean;
  setOpen(param: boolean): void;
  onCreate?(widget: InsightWidget): void;
  data?: InsightWidget;
}

export const NewFigureModal = ({ open, setOpen, data }: Props) => {
  const title = useInput("Untitled");
  const property = useInput("amount");
  const chartType = useInput("line");
  const timeRange = useInput("1 months");
  const interval = useInput("1d");
  const aggregate = useInput("count");
  const query = useInput("");
  const groupBy = useInput("");
  const addr = useInput(undefined);
  const textColor = useInput("#2e49bc");
  const backgroundColor = useInput("white");
  const showArchived = useInput(false);
  const [dataSource, setDataSource] = useState<string>("");
  const [text, setText] = useState(
    "Select a Data Source and Query to view analytics data",
  );
  const {
    currentProjectId,
    currentProject,
    currentInsightsLayout,
    setCurrentInsightsLayout,
    metricData,
  } = useMonitor();

  const groupByData = useMemo(() => {
    const data = metricData.find((item) => item.name === dataSource);
    if (data) {
      return [
        { id: "None", title: "<Empty>", value: "" },
        ...data.fields
          .filter((item) => item.type === "string")
          .map((item) => ({
            id: item.name,
            title: item.label,
            value: item.name,
          })),
      ];
    }
    return [];
  }, [metricData, dataSource]);
  const aggregateData = useMemo(() => {
    const data = metricData.find((item) => item.name === dataSource);
    if (data) {
      return data.possibleAggregates.map((item) => ({
        id: item.name,
        title: item.label,
        value: item.name,
      }));
    }
    return [];
  }, [metricData, dataSource]);

  const queryData = useMemo(() => {
    if (dataSource) {
      const data = metricData.find((item) => item.name === dataSource);
      if (data) {
        return [
          ...data.predefinedQueries.map((query) => ({
            id: query.name,
            name: query.name,
            description: query.description,
            value: query.name,
            query: {
              chartType: query.chart,
              aggregate: query.aggregate,
              groupBy: query.categorizedBy,
              interval: query.interval,
              timeRange: query.timeRange,
            },
          })),
          {
            id: "Customize",
            name: "Customize...",
            description: "Customize your query",
            value: "Customize",
            query: {
              chartType: "value",
              aggregate: "sum",
              groupBy: "",
              interval: "1d",
              timeRange: "1 months",
            },
          },
        ];
      }
    }
    return [];
  }, [dataSource]);

  const [isCustomizing, setIsCustomizing] = useState(false);
  const { isLoading: isCreating, mutate: createFigure } = useMutation(
    (widget: InsightWidget) => addInsightsWidget(currentProjectId, widget),
    {
      onSuccess: (res: any) => {
        setCurrentInsightsLayout([...currentInsightsLayout, res.data]);
        setOpen(false);
        reset();
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    },
  );
  const { isLoading: isUpdating, mutate: updateFigure } = useMutation(
    (widget: InsightWidget) =>
      updateInsightsWidget(currentProjectId, data?.id, widget),
    {
      onSuccess: (res: any) => {
        setCurrentInsightsLayout(
          currentInsightsLayout.map((item) => {
            if (item.id === res.data.id) {
              return res.data;
            } else {
              return item;
            }
          }),
        );
        setOpen(false);
        reset();
      },
      onError: (err: any) => {
        toast.error(err.response.data.message);
      },
    },
  );

  const reset = () => {
    title.setValue("Untitled");
    property.setValue("amount");
    chartType.setValue("line");
    timeRange.setValue("1 months");
    interval.setValue("1d");
    addr.setValue(undefined);
    setIsCustomizing(false);
    query.setValue("");
    setDataSource("");
    textColor.setValue("#2e49bc");
    backgroundColor.setValue("white");
    showArchived.setValue(false);
  };
  useEffect(() => {
    if (open && data) {
      title.setValue(data.name);
      setDataSource(data.queries[0].metric);
      query.setValue(data.queries[0].queryName);
      property.setValue(data.queries[0].property);
      chartType.setValue(data.queries[0].chartType);
      timeRange.setValue(data.queries[0].timeRange);
      interval.setValue(data.queries[0].interval);
      aggregate.setValue(data.queries[0].aggregate);
      groupBy.setValue(data.queries[0].groupBy);
      showArchived.setValue(data.queries[0].showArchived);
      textColor.setValue(data.textColor);
      backgroundColor.setValue(data.backgroundColor);

      if (data.queries[0].addressId) {
        addr.setValue(
          currentProject?.addresses?.find(
            (item) => item.id === data.queries[0].addressId,
          ),
        );
      } else {
        addr.setValue(undefined);
      }
      if (data.queries[0].queryName === "Customize") {
        setIsCustomizing(true);
      }
    } else {
      reset();
    }
    return () => {};
  }, [open]);

  const handleCreate = async () => {
    if (data) {
      updateFigure({
        ...data,
        name: title.value,
        textColor: textColor.value,
        backgroundColor: backgroundColor.value,
        queries: [
          {
            metric: dataSource,
            property: property.value,
            queryName: query.value,
            chartType: chartType.value,
            timeRange: timeRange.value,
            interval: interval.value,
            groupBy: groupBy.value,
            aggregate: aggregate.value,
            addressId: addr.value?.id,
            showArchived: showArchived.value,
          },
        ],
      });
    } else {
      const width = 4;
      const height = 2;
      createFigure({
        name: title.value,
        queries: [
          {
            metric: dataSource,
            queryName: query.value,
            property: property.value,
            chartType: chartType.value,
            timeRange: timeRange.value,
            interval: interval.value,
            groupBy: groupBy.value,
            aggregate: aggregate.value,
            addressId: addr.value?.id,
            showArchived: showArchived.value,
          },
        ],
        width,
        height,
        textColor: textColor.value,
        backgroundColor: backgroundColor.value,
        x:
          currentInsightsLayout.length === 0 ||
          currentInsightsLayout[currentInsightsLayout.length - 1].x +
            currentInsightsLayout[currentInsightsLayout.length - 1].width +
            width >
            INSIGHT_LAYOUT_COL_NUM
            ? 0
            : currentInsightsLayout[currentInsightsLayout.length - 1].x +
              currentInsightsLayout[currentInsightsLayout.length - 1].width,
        y:
          currentInsightsLayout.length === 0
            ? 0
            : currentInsightsLayout[currentInsightsLayout.length - 1].x +
                  currentInsightsLayout[currentInsightsLayout.length - 1]
                    .width +
                  width >
                INSIGHT_LAYOUT_COL_NUM
              ? currentInsightsLayout[currentInsightsLayout.length - 1].y +
                currentInsightsLayout[currentInsightsLayout.length - 1].height
              : currentInsightsLayout[currentInsightsLayout.length - 1].y,
      });
    }
  };

  const handleDataSourceChange = (option: any) => {
    setDataSource(option || "");
    query.setValue("");
    chartType.setValue("");
    setIsCustomizing(false);
    backgroundColor.setValue("white");
    textColor.setValue("#2e49bc");
  };

  useEffect(() => {
    if (!dataSource) {
      setText("Select a Data Source and Query to view analytics data");
    } else if (!query.value) {
      setText(`Select a Query to view ${dataSource} analytics data`);
    } else {
      setText("");
    }
    return () => {};
  }, [dataSource, query.value]);

  const handleSelectQuery = (queryVal: string) => {
    query.setValue(queryVal);
    if (queryVal) {
      if (queryVal === "Customize") {
        setIsCustomizing(true);
      } else {
        const existing = queryData.find((item) => item.value === queryVal);
        if (existing) {
          chartType.setValue(existing.query.chartType);
          timeRange.setValue(existing.query.timeRange);
          interval.setValue(existing.query.interval || "1d");
          aggregate.setValue(existing.query.aggregate);
          groupBy.setValue(existing.query.groupBy);
          title.setValue(existing.name);
        }
        setIsCustomizing(false);
      }
      setText("");
      // metric.setValue(
      //   metricData && metricData?.length > 0 ? metricData?.[0]?.name : ""
      // );
    }
  };

  const handleTimeRangeChange = (param: string) => {
    if (param !== timeRange.value) {
      timeRange.setValue(param);
      query.setValue("Customize");
      setIsCustomizing(true);
    }
  };

  return (
    <SidePanel
      // id="new-figure"
      title={data ? "Edit Chart" : "New Chart"}
      icon="address"
      // className="new-figure-modal-container"
      open={open}
      setOpen={(param) => {
        setOpen(param);
        reset();
      }}
    >
      <div className="new-figure-modal">
        <div className="new-figure-modal__preview mb-3">
          <FigureGraph
            metric={dataSource}
            queryName={query.value}
            address={addr.value?.id}
            property={property.value}
            chartType={chartType.value}
            timeRange={timeRange.value}
            interval={interval.value}
            aggregate={aggregate.value}
            groupBy={groupBy.value}
            text={text}
            textColor={textColor.value}
            backgroundColor={backgroundColor.value}
            showArchived={showArchived.value}
          />
        </div>

        <hr />
        <div className="d-block d-sm-flex my-3">
          <div className="new-figure-modal__index my-auto me-2">1</div>
          <div className="new-figure-modal__heading  my-2 my-sm-auto">
            <div>Data Source</div>
          </div>
        </div>
        {/* <div className="new-figure-modal__metric">
          <MetricSelect
            metricData={metricData}
            onChange={handleDataSourceChange}
            value={dataSource}
          />
        </div> */}
        <SelectZone
          className="pl-4"
          data={metricData.map((item) => ({ ...item, id: item.name }))}
          onChange={handleDataSourceChange}
          value={dataSource}
        />
        {dataSource && (
          <div>
            <hr />
            <div className="d-flex">
              <div className="new-figure-modal__index my-auto me-2">2</div>
              <div className="my-2 my-sm-auto me-2">
                <div>Query</div>
              </div>
            </div>
            <div className="pl-4">
              <SelectZone
                data={queryData}
                {...query}
                onChange={(e) => handleSelectQuery(e)}
              />

              {isCustomizing && (
                <div className="pl-4">
                  <div className="d-block d-sm-flex my-3">
                    <div>
                      <div className="new-figure-modal__heading  my-2 my-sm-auto">
                        <div>Chart Type</div>
                      </div>
                      <div className="new-figure-modal__chart-type">
                        <Select1
                          className="d-flex"
                          values={FIGURE_CHART_TYPE_DATA}
                          {...chartType}
                        />
                      </div>
                    </div>
                    {chartType.value !== "table" && (
                      <>
                        {!(
                          (dataSource === "alerts" || dataSource === "bots") &&
                          chartType.value === "value"
                        ) && (
                          <div className="ms-0 ms-sm-2">
                            <div className="ml my-2 my-sm-auto">
                              <div>Aggregate</div>
                            </div>
                            <div className="new-figure-modal__aggregate">
                              <Select1
                                className="d-flex"
                                values={aggregateData}
                                {...aggregate}
                                placeholder="Select aggregate"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    <div className=" ms-0 ms-sm-2">
                      <div className=" my-2 my-sm-auto ms-auto">
                        <div>Time Range</div>
                      </div>
                      <div className="new-figure-modal__time-range">
                        <Select1
                          className="d-flex"
                          values={FIGURE_TIME_RANGE_DATA}
                          {...timeRange}
                          onChange={handleTimeRangeChange}
                        />
                      </div>
                    </div>
                    {(chartType.value === "line" ||
                      chartType.value === "bar" ||
                      chartType.value === "area") && (
                      <div className="ms-0 ms-sm-2">
                        <div className=" my-2 my-sm-auto">
                          <div>Interval</div>
                        </div>
                        <div className="new-figure-modal__interval">
                          <Select1
                            className="d-flex"
                            values={FIGURE_INTERVAL_DATA}
                            {...interval}
                          />
                        </div>
                      </div>
                    )}
                    {chartType.value === "pie" && (
                      <div className="ms-0 ms-sm-2">
                        <div className="my-2 my-sm-auto">
                          <div>Group By</div>
                        </div>
                        <div className="new-figure-modal__group">
                          <Select1
                            className="d-flex"
                            values={groupByData}
                            {...groupBy}
                            placeholder="<Empty>"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="d-block d-sm-flex">
                    {dataSource === "alerts" && (
                      <div className="ms-2">
                        <div className="new-figure-modal__show-archives my-2 my-sm-auto ms-0">
                          <div>Show Archived Alerts</div>
                        </div>
                        <Switch className="my-2" label="" {...showArchived} />
                      </div>
                    )}
                  </div>
                  <hr />
                </div>
              )}
              {query.value && (
                <div>
                  <div className="d-block d-sm-flex my-3">
                    {/* {query.value && ( */}
                    <div className="d-block mb-2">
                      <div className="my-2 my-sm-auto">
                        <div>Title</div>
                      </div>
                      <div className="new-figure-modal__title">
                        <Input className="d-flex" {...title} />
                      </div>
                    </div>

                    <div className="ms-0 ms-sm-2">
                      <div className=" my-2 my-sm-auto ms-auto me-2">
                        <div>Address</div>
                      </div>
                      <div className="new-figure-modal__address">
                        <AddressSelect
                          className="d-flex"
                          values={[...(currentProject?.addresses || [])]}
                          {...addr}
                          placeholder="All"
                        />
                      </div>
                    </div>
                    {["line", "bar", "value"].includes(chartType.value) && (
                      <div className="ms-0 ms-sm-2">
                        <div className="new-figure-modal__heading my-2 my-sm-auto ms-0">
                          <div>Theme</div>
                        </div>
                        <div className="new-figure-modal__theme ms-0">
                          <ColorThemeSelect
                            value={{
                              textColor: textColor.value,
                              backgroundColor: backgroundColor.value,
                            }}
                            onChange={(value) => {
                              textColor.setValue(value.textColor);
                              backgroundColor.setValue(value.backgroundColor);
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <hr />

        <div className="d-flex mt-4">
          <Button
            className="ms-auto"
            isLoading={isCreating || isUpdating}
            disabled={isCreating || isUpdating || !dataSource || !query.value}
            onClick={handleCreate}
          >
            {data ? "Update" : "Create"}
          </Button>
        </div>
      </div>
    </SidePanel>
  );
};
