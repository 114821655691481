import { API } from "@/utils/api";
import {
  AuditRequest,
  RegisterPhoneNumberPayload,
  UserUpdatePayload,
  VerifyPhoneNumberPayload,
  VerifyTotpPayload,
} from "@/types";

/* Get current user info */
export const getUser = async () => {
  const response = await API().get(`/user`);
  return response.data.data.user;
};

/* Update user info */
export const updateUser = async (userId: string, data: UserUpdatePayload) => {
  const response = await API().put(`/user/${userId}`, data);
  return response.data.data.user;
};

/* Create team member */
export const createTeamMember = async (payload: {
  username: string;
  email: string;
}) => {
  const response = await API().post(`/team_member`, payload);
  return response.data.data.user;
};

/* Get team members */
export const getTeamMembers = async () => {
  const response = await API().get(`/team_members`);
  return response.data.data.users;
};

/* Delete team member */
export const deleteTeamMember = async (userId: string) => {
  const response = await API().delete(`/team_member/${userId}`);
  return response.data;
};

export const registerPhoneNumber = async (
  payload: RegisterPhoneNumberPayload,
) => {
  const response = await API().post("phone_number/_register", payload);
  return response.data;
};

export const verifyPhoneNumber = async (payload: VerifyPhoneNumberPayload) => {
  const response = await API().post("phone_number/_verify", payload);
  return response.data;
};

export const registerTotp = async () => {
  const response = await API().post("totp/_register", {});
  return response.data;
};

export const verifyTotp = async (payload: VerifyTotpPayload) => {
  const response = await API().post("totp/_verify", payload);
  return response.data;
};

export const disableMfa = async () => {
  const response = await API().post("disablemfa", {});
  return response.data;
};

/* Get a quote for launch audit */
export const postAuditRequest = async (payload: AuditRequest) => {
  const response = await API().post(`/quote`, payload);
  return response.data;
};
