import { useAuth } from "@/contexts";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const auth = useAuth();

  if (auth.isLoggedIn && auth.userInfo && localStorage.getItem("jwt")) {
    return <Outlet />;
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoutes;
