import { ReactElement } from "react";

export type BillingPeriod = "monthly" | "yearly";
export type PlanName = "Free" | "Basic" | "Build" | "Scale" | "Diamond";
/* Type for a plan */
export interface Plan {
  name: PlanName;
  billingPeriod: BillingPeriod;
  start_at: Date;
  expire_at: Date;
  isAutoRenew: boolean;
  nextPlan?: PlanName;
}

export interface PlanData {
  name: PlanName;
  description: string;
  monthlyPrice: number;
  yearlyPrice: number;
  isPopular: boolean;
  features: (string | ReactElement)[];
}

/* Type for a user */
export interface User {
  password: string;
  username: string;
  email: string;
  organization?: {
    name: string;
    country: string;
    state: string;
    city: string;
    address: string;
    zipCode: string;
  };
  token: string;
  plan: Plan;
  balance: number;
  certCredit: number;
  isAdmin: boolean;
  isVerified: boolean;
  isDisabled: boolean;
  isTeamMember: boolean;
  isMfaEnabled: boolean;
  isNew: boolean;
  factorSid: string;
  mfaChannel: string;
  id?: string;
  addresses: string[];
  stripeCustomerId: string;
  phoneNumber?: string;
  tasks: Task[];
  created_at: Date;
  updated_at: Date;
}

export interface Address {
  id: string;
  address: string;
  alias: string;
  chain: string;
  network: string;
}

/* Type for a user */
export interface UserUpdatePayload {
  username?: string;
  organization?: string;
  country?: string;
  city?: string;
  address?: string;
  state?: string;
  zipCode?: string;
}

export interface Coupon {
  id: string;
  couponCode: string;
  multiplier: number;
  durationInMonths: number;
  name: string;
  plans: string[];
}

export interface UserPayload {
  password: string;
  username: string;
  email: string;
  organization: string;
}

export interface CouponPayload {
  couponCode: string;
  couponName: string;
  multiplier: number;
  amount: number;
  isDisabled: boolean;
  id?: string;
}

export interface LoginPayload {
  email: string;
  password: string;
}

export interface MfaLoginPayload {
  token: string;
  channel: string;
  code: string;
}

export interface RegisterPhoneNumberPayload {
  phoneNumber: string;
  channel: string;
}

export interface VerifyPhoneNumberPayload {
  phoneNumber: string;
  channel: string;
  code: string;
  enableMfa: boolean;
}

export interface VerifyTotpPayload {
  factorSid: string;
  code: string;
}

export interface CreateTask {
  taskName: string;
  githubUrl?: string;
  code?: File;
}

export interface CreateBot {
  id?: string;
  projectId: string;
  botTemplateId: string;
  name: string;
  targets: Address[];
  severity: string;
  args: any;
  receiverIds: string[];
  network: string;
}

/* Type for a task */
export interface Task {
  id: string;
  name: string;
  repoName?: string;
  tag?: string;
  isPrivate?: boolean;
  hasAskedAudit: boolean;
  commit: string;
  size: number;
  status: TaskStatus;
  price: number;
  isPaid: boolean;
  isArchived: boolean;
  created_at: Date;
  updated_at: Date;
  message?: string;
  issueCount: number;
  criticalIssueCount: number;
  reports: ReportSummary[];
}

export interface RawReport {
  id: string;
  created_at: Date;
  title: string;
  issueCount: number;
  criticalIssueCount: number;
  raceData: RaceData;
  verData: VerData;
  projectDir: string;
  comparedWithBase: boolean;
  newDataRaces: number[];
  newCosplayAccounts: number[];
  newUnsafeOperations: number[];
  newUntrustfulAccounts: number[];
  baseGitCommitLog: string;
  currGitCommitLog: string;
  currentGitUrl: string;
  taskId: string;
  taskName: string;
}

export interface Report {
  id: string;
  created_at: Date;
  title: string;
  issues: Issue[];
  verData: VerData;
  projectDir: string;
  comparedWithBase: boolean;
  baseGitCommitLog: string;
  currGitCommitLog: string;
  currentGitUrl: string;
}

/* Type for a count data */
export interface DataPoint {
  color?: string;
  name: string;
  value: number;
}

/* Criteria used for sorting programs*/
export enum ProgramSortingCriterion {
  Name = "Name",
  Count = "Count",
}

/* Criteria used for sorting */
export enum IssueSortingCriterion {
  Type = "Type",
  Newness = "Newness",
  Severity = "Severity",
}

/* Criteria used for Category Filtering */
export enum TypeFilter {
  All = "All",
  UntrustfulAccount = "Untrustful Account",
  CosplayAccount = "Cosplay Account",
  UnsafeOperation = "Unsafe Operation",
}

/* Type for task status */
export enum TaskStatus {
  Ready = "Ready",
  InProgress = "In-progress",
  Completed = "Completed",
  Error = "Error",
}

export enum PageStatus {
  Ready = "Ready",
  InProgress = "In-progress",
  Completed = "Completed",
  Error = "Error",
}

export enum Severity {
  Low = "low",
  Medium = "medium",
  High = "high",
  Critical = "critical",
}

export interface Rule {
  id: string;
  name: string;
  description: string;
  url: string;
  free?: boolean;
}

export interface AuditRequest {
  name: string;
  email: string;
  projectName: string;
  jobTitle: string;
  desiredDate: string;
  country: string;
  alternativeContact: string;
  additionalInformation: string;
}

/* Type for a report */
export interface RaceData {
  bcFile: string;
  version: number;
  dataRaces: DataRace[];
  raceConditions: RaceCondition[];
  toctous: DataRace[];
  mismatchedAPIs: MismatchedAPI[];
  untrustfulAccounts: UntrustfulAccount[];
  unsafeOperations: UnsafeOperation[];
  cosplayAccounts: CosplayAccount[];
}

interface VerData {
  Version: string;
  EnableMismatchedAPIs: boolean;
}

export interface ReportSummary {
  id: string;
  title: string;
  issueCount: number;
  version: string;
}

export interface ThreadAccess {
  col: number;
  dir: string;
  filename: string;
  line: number;
  snippet: string;
  sourceLine: string;
  stacktrace: string[];
}

interface SharedObj {
  dir: string;
  filename: string;
  line: number;
  name: string;
  sourceLine: string;
}

interface DataRace {
  sharedObj: SharedObj;
  access1: ThreadAccess;
  access2: ThreadAccess;
  isOmpRace: boolean;
  priority: number;
}

export interface Issue {
  description: string;
  isNew: boolean;
  type: string;
  id: string;
  index: number; // TODO: Change to id
  errorMsg: string;
  priority: number;
  hide: boolean;
  ignore: boolean;
  inst?: ThreadAccess;
  inst1?: ThreadAccess;
  inst2?: ThreadAccess;
}

interface MismatchedAPI extends Issue {}
interface UntrustfulAccount extends Issue {}
interface UnsafeOperation extends Issue {}
interface CosplayAccount extends Issue {}

interface RaceCondition {
  SharedObj: SharedObj;
  Access1: ThreadAccess;
  Access2: ThreadAccess;
  Access3: ThreadAccess;
  IsAV: boolean;
  Priority: number;
}

export interface BotTemplate {
  id: string;
  name: string;
  description: string;
  command: string;
  defaultSeverity: string;
  args: BotArgument[];
  chain: string;
  shortName: string;
}

export interface BotArgument {
  name: string;
  description: string;
  default: string;
  optional: boolean;
}

export interface Bot {
  id: string;
  userId: string;
  projectId: string;
  botTemplateId: string;
  description: string;
  name: string;
  message: string;
  targets: Address[];
  email: string;
  network: string;
  phoneNumber: string;
  severity: string;
  status: string;
  command: string;
  template: string;
  created_at: Date;
  updated_at: Date;
  args: any;
  receiverIds: string[];
}

/* Type for an alert */
export interface Alert {
  id: string;
  botId: string;
  userId: string;
  projectId: string;
  severity: string;
  template: string;
  botName: string;
  detail: string;
  chain: string;
  network: string;
  targets: Address[];
  tx: string[];
  args: any;
  isArchived: boolean;
  created_at: Date;
  isRead: boolean;
}
export interface DeleteUserPayload {
  userId: number;
  reason: string;
  password: string;
}

export interface RerunTaskPayload {
  taskId: string;
  taskName: string;
  code?: string;
  githubUrl?: string;
}

export type IssueCategoryType =
  | "UntrustfulAccount"
  | "UnsafeOperation"
  | "CosplayAccount";
export type DismissReasonType = "WontFix" | "FalsePositive" | "Test";

export interface DismissIssuePayload {
  reportId: string;
  index: number;
  comment: string;
  category: IssueCategoryType;
  dismissReason: DismissReasonType;
}

export interface UndismissIssuePayload {
  reportId: string;
  index: number;
  category: IssueCategoryType;
}

export interface Project {
  id: string;
  userId: string;
  name: string;
  githubUrl: string;
  addresses: Address[];
  created_at: Date;
  updated_at: Date;
  botCount: number;
}

export interface InsightToken {
  token: string;
  data: {
    date: number;
    amount: number;
    signers: string[];
    tx: string;
  }[];
  mint: string;
  threshold: number;
}

export interface InsightTopSigner {
  value: number | undefined;
  signer: string;
  freq: number;
}

export interface MaxTransferPayload {
  id: string;
  tokens: { [key: string]: { Amount: number; Tx: string } };
  signers: string[];
  blocktime: number;
}

export interface PayloadInvariants {
  contract: string;
  id: number;
  instruction: {
    index: number;
    signer: boolean;
    owners: string[];
    keys: string[];
  };
  name: string;
  sighash: string;
  _id: string;
}

export interface MaxTokenTransfers {
  mint: string;
  token: string;
  value: number;
}
export interface PricePayload {
  id: string;
  price: number;
  unixtime: number;
}

export interface Receiver {
  id: string;
  userId: string;
  label: string;
  isConnected: boolean;
  receiverType: "email" | "sms" | "slack" | "telegram" | "discord" | "webhook";
  receiver: {
    email?: string;
    phoneNumber?: string;
    webhookUrl?: string;
    chatId?: string;
    chatName?: string;
    secret?: string;
    token?: string;
    channel?: string;
  };
  // | EmailReceiver
  // | SmsReceiver
  // | SlackReceiver
  // | TelegramReceiver
  // | DiscordReceiver
  // | WebhookReceiver;
  created_at: Date;
  telegramToken?: string;
}

export interface BotTx {
  id: string;
  isConnected: boolean;
  tx: string;
  label: string;
}
export interface EmailReceiver {
  email: string;
}
export interface SmsReceiver {
  phoneNumber: string;
}
export interface SlackReceiver {
  webhookUrl: string;
}
export interface TelegramReceiver {
  chatId: number;
}
export interface DiscordReceiver {
  webhookUrl: string;
}
export interface WebhookReceiver {
  webhookUrl: string;
  secret: string;
}

export interface CreateBotTx {
  label: string;
  tx: string;
}

export interface Name {
  id: string;
  name: string;
  address: string;
  type: string;
}

export interface PDAInvariant {
  accounts: any;
  id: string;
  is_mutable: boolean;
  key_values: { key: string; value: string };
  name: string;
  timestamp: number;
}
export interface PDAHistory {
  accounts: any;
  id: string;
  is_mutable: boolean;
  key_values: { key: string; value: string };
  name: string;
  timestamp: number;
}

export interface InstructionVariants {
  category: string;
  expression: string;
  false_counts: number;
  id: string;
  pda_related: boolean;
  profile: string;
  status: number;
  true_counts: number;
}

export interface DTValue {
  preset?: string;
  time?: Date;
}

export interface InsightLayout {
  id?: string;
  projectId: string;
  widgets: InsightWidget[];
}

export interface InsightWidget {
  id?: string;
  name: string;
  queries: InsightQuery[];
  width: number;
  height: number;
  x: number;
  y: number;
  backgroundColor?: string;
  textColor?: string;
}
export interface InsightQuery {
  metric: string;
  queryName: string;
  property: string;
  addressId?: string;
  chartType: string;
  timeRange: string;
  aggregate: string;
  groupBy: string;
  interval: string;
  showArchived: boolean;
}

export interface InsightMetric {
  name: string;
  description: string;
  category: string;
  chain: string;
  network: string;
  availableQueries: string[];
  fields: {
    name: string;
    label: string;
    type: string;
    possibleValues: string[];
  }[];
  possibleAggregates: {
    name: string;
    label: string;
  }[];
  predefinedQueries: {
    aggregate: string;
    categorizedBy: string;
    chart: string;
    filters: string[];
    interval: string;
    name: string;
    timeRange: string;
    type: string;
    description: string;
  }[];
}

export interface InsightEventPayload {
  metric: string;
  from: number;
  to: number;
  filters: {
    filterBy: string;
    filterer: string;
  }[];
  sortBy: string;
  sortOrder: number;
  offset?: number;
  size?: number;
  timeField?: string;
}
export interface InsightTimeSeriesPayload {
  metric: string;
  from: number;
  to: number;
  filters: {
    filterBy: string;
    filterer: any;
  }[];
  interval?: string;
  aggregate: string;
  timeField?: string;
}
export interface InsightCategoryPayload {
  metric: string;
  categorizedBy: string;
  from: number;
  to: number;
  filters: {
    filterBy: string;
    filterer: string;
  }[];
  aggregate: string;
  timeField?: string;
}
export interface InsightTotalPayload {
  metric: string;
  from: number;
  to: number;
  filters: {
    filterBy: string;
    filterer: string;
  }[];
  aggregate: string;
  timeField?: string;
}
export interface Tx {
  hash: string;
  blockNumber: number;
  blockTime: string;
  score: number;
}
