import { Link } from "react-router-dom";
import { IoLogIn } from "react-icons/io5";
import { OnboardingLayout } from "@/layouts";
import { Button, Box } from "@/components";
import { resendEmail } from "@/api";

import { useSearchParams } from "react-router-dom";

export const ResendEmailPage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get("email");

  const handleResendLink = async () => {
    if (email) {
      await resendEmail(email);
    }
  };

  return (
    <OnboardingLayout>
      <div className="onboarding-title">
        <h3>Get Started</h3>
        <div>
          <span>Have an account?</span>
          <Link to="/login">
            <IoLogIn />
            Sign in
          </Link>
        </div>
      </div>
      <Box title="Check your email">
        <p>
          A verification letter has been sent your email address. Please check
          your inbox and click verification link to finish registration process.
        </p>
        <Button className="w-100" onClick={handleResendLink}>
          Resend link
        </Button>
      </Box>
    </OnboardingLayout>
  );
};
