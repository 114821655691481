/* eslint-disable react-hooks/exhaustive-deps */
import { API_VERSION, WS_URL } from "@/config";
import { createContext, ReactNode, useEffect, useState } from "react";
import useInterval from "use-interval";

export interface WsState {
  webSocket: WebSocket | null;
  connectWebSocket(): void;
  disconnectWebSocket(): void;
}

const initialState: WsState = {
  webSocket: null,
  connectWebSocket: () => {},
  disconnectWebSocket: () => {},
};

interface IWebSocketProvider {
  children: ReactNode;
}

export const WebSocketContext = createContext(initialState);

export const WebSocketProvider = (props: IWebSocketProvider) => {
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);
  const connectWebSocket = async () => {
    const jwt = localStorage.getItem("jwt");
    if (!jwt) return;
    if (webSocket) {
      webSocket?.close();
    }
    const ws = new WebSocket(`${WS_URL}/${API_VERSION}/ws?token=${jwt}`);
    if (import.meta.env.NODE_ENV === "development") {
      ws.addEventListener("message", (event: MessageEvent<string>) =>
        console.log(JSON.parse(event.data)),
      );
    }
    setWebSocket(ws);
    ws.onclose = () => {
      // automatically try to reconnect on connection loss
      connectWebSocket();
    };
  };

  const disconnectWebSocket = () => {
    if (webSocket) {
      webSocket?.close();
      setWebSocket(null);
    }
  };

  useInterval(() => {
    webSocket?.send(
      JSON.stringify({
        type: "heartbeat",
      }),
    );
  }, 29000);

  useEffect(() => {
    connectWebSocket();
  }, []);
  return (
    <WebSocketContext.Provider
      value={{
        webSocket,
        connectWebSocket,
        disconnectWebSocket,
      }}
    >
      {props.children}
    </WebSocketContext.Provider>
  );
};
