import React, { forwardRef, useImperativeHandle, useRef } from "react";
import classNames from "classnames";
import { nanoid } from "nanoid";
import "./style.scss";

interface Props {
  label?: string;
  type?: "text" | "password" | "email" | "number" | "phone";
  className?: string;
  maxLength?: number;
  placeholder?: string;
  isRequired?: boolean;
  disabled?: boolean;
  value?: string;
  autoFocus?: boolean;
  onBlur?(): void;
  onChange?(param: string): void;
  isSigner?: boolean;
  isWritable?: boolean;
}

export const Input = forwardRef(
  (
    {
      label,
      className,
      type = "text",
      placeholder,
      maxLength,
      disabled = false,
      value = "",
      isRequired,
      autoFocus = false,
      onBlur = () => {},
      onChange = () => {},
      isSigner = false,
      isWritable = false,
    }: Props,
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        inputRef.current && inputRef.current.focus();
      },
    }));

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      let value = event.target.value || "";
      if (type === "number") {
        value = value.replace(/[^[\\./d]]/g, "");
      }
      onChange(maxLength ? value.slice(0, maxLength) : value);
    };
    const id = nanoid();
    return (
      <div className={classNames("input-control", className)}>
        {label && (
          <div className="d-flex justify-content-between">
            <label htmlFor={id} className="input-control__label">
              {label}
            </label>
          </div>
        )}

        <input
          value={value || ""}
          id={id}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          type={type}
          autoComplete={"new-password"}
          disabled={disabled}
          ref={inputRef}
          autoFocus={autoFocus}
          className={classNames({
            active: value,
          })}
          required={isRequired}
        />
        <div className="input-control__symbols">
          {isSigner && <div className="input-control__signer">Signer</div>}
          {isWritable && (
            <div className="input-control__writable">Writable</div>
          )}
        </div>
      </div>
    );
  },
);
