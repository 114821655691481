import { API } from "@/utils/api";
import {
  InsightEventPayload,
  InsightTimeSeriesPayload,
  InsightCategoryPayload,
  InsightTotalPayload,
} from "@/types";

export const getInsightsMetrics = async () => {
  const response = await API().get(`/insights/metrics`);
  return response.data.data.metrics || [];
};

export const getInsightsEvent = async (payload: InsightEventPayload) => {
  const response = await API().post(`/insights/events`, {
    ...payload,
    timeField:
      payload.metric === "alerts" || payload.metric === "bots"
        ? "created_at"
        : undefined,
  });
  return response.data;
};

export const getInsightsTimeSeries = async (
  payload: InsightTimeSeriesPayload,
) => {
  const response = await API().post(`/insights/time_series`, {
    ...payload,
    timeField:
      payload.metric === "alerts" || payload.metric === "bots"
        ? "created_at"
        : undefined,
  });
  return response.data;
};

export const getInsightsCategorical = async (
  payload: InsightCategoryPayload,
) => {
  const response = await API().post(`/insights/categorical`, {
    ...payload,
    timeField:
      payload.metric === "alerts" || payload.metric === "bots"
        ? "created_at"
        : undefined,
  });
  return response.data;
};

export const getInsightsTotal = async (payload: InsightTotalPayload) => {
  const response = await API().post(`/insights/total`, {
    ...payload,
    timeField:
      payload.metric === "alerts" || payload.metric === "bots"
        ? "created_at"
        : undefined,
  });
  return response.data;
};
