/* eslint-disable react-refresh/only-export-components */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { getBotTemplates as getBotTemplatesAPI } from "@/api";
import { BotTemplate, Name } from "@/types";
import React, { createContext, useContext } from "react";
import { useLocalstorageState } from "rooks";
import useSessionStorageState from "use-session-storage-state";

export interface DataState {
  botTemplates: BotTemplate[];
  names: Name[];
  initData(): void;
  getBotTemplates(): Promise<any>;
  getBotTemplateById(id: string): Promise<any>;
}

const initialState: DataState = {
  botTemplates: [],
  names: [],
  initData: () => {},
  getBotTemplates: () => new Promise(() => {}),
  getBotTemplateById: () => new Promise(() => {}),
};

export const DataContext = createContext<DataState>(initialState);

export const useData = () => useContext(DataContext);

const STORAGE_KEY = "sec3-data-state";

interface Props {
  children: React.ReactNode;
}

export const DataProvider = ({ children }: Props) => {
  const [value] = useLocalstorageState(STORAGE_KEY, {
    botTemplates: [],
    names: [],
  });
  const [botTemplates, setBotTemplates] = useSessionStorageState(
    "bot-templates",
    {
      defaultValue: [],
    },
  );

  const initData = async () => {
    await initBotTemplates();
  };

  const initBotTemplates = async () => {
    const res = await getBotTemplatesAPI();
    setBotTemplates(res);
    return res;
  };

  const getBotTemplates = async () => {
    if (botTemplates.length === 0) {
      return await initBotTemplates();
    }
    return botTemplates;
  };

  const getBotTemplateById = async (id: string) => {
    let res: BotTemplate[] = [];
    if (botTemplates.length === 0) {
      res = await initBotTemplates();
    } else {
      res = botTemplates;
    }
    return res.find((v) => v.id === id);
  };

  return (
    <DataContext.Provider
      value={{
        getBotTemplates,
        initData,
        getBotTemplateById,
        ...value,
        botTemplates,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};
