import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import styled from "styled-components";

interface Props {
  onClick?(): void;
  status?: string;
  className?: string;
}
export const SortIcon = ({
  onClick = () => {},
  status = "none",
  className,
}: Props) => {
  return (
    <Container onClick={onClick} className={className}>
      {status === "none" ? (
        <FaSort size={12} color="#bbb" />
      ) : status === "up" ? (
        <FaSortUp size={12} color="#111843" />
      ) : (
        <FaSortDown size={12} color="#111843" />
      )}
    </Container>
  );
};
const Container = styled.span`
  height: fit-content;
  display: flex;
`;
