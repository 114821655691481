import { deleteInsightsWidget } from "@/api";
import { Button, Modal } from "@/components";
import { useMonitor } from "@/contexts";
import { InsightWidget } from "@/types";
import { useMutation } from "react-query";
import "./style.scss";

interface Props {
  open: boolean;
  setOpen(param: boolean): void;
  data: InsightWidget;
  onDelete?(): void;
}
export const DeleteFigureModal = ({ open, setOpen, data }: Props) => {
  const reset = () => {};

  const { currentProjectId, setCurrentInsightsLayout, currentInsightsLayout } =
    useMonitor();

  const {
    isLoading: isDeleting,
    error,
    mutate: deleteWidget,
  } = useMutation(
    async () => {
      await deleteInsightsWidget(currentProjectId, data.id);
    },
    {
      onSuccess: () => {
        setCurrentInsightsLayout(
          currentInsightsLayout.filter(
            (widget: InsightWidget) => widget.id !== data.id,
          ),
        );
      },
      onError: () => {},
    },
  );

  return (
    <Modal
      title={"Delete Chart"}
      open={open}
      setOpen={(param) => {
        setOpen(param);
        reset();
      }}
    >
      <div className="delete-address-modal">
        <div className="mb-5">Are you sure to delete {data.name} chart?</div>
        {error && (
          <div className="text-right mt-3 color-red">{error.toString()}</div>
        )}
        <div className="d-flex mt-4">
          <Button
            className="ms-auto"
            color="light"
            onClick={() => setOpen(false)}
          >
            Close
          </Button>
          <Button
            className="ms-3"
            color="secondary"
            isLoading={isDeleting}
            type="submit"
            disabled={isDeleting}
            onClick={() => deleteWidget()}
          >
            Delete
          </Button>
        </div>
      </div>
    </Modal>
  );
};
