import { resendEmail } from "@/api";
import { Box, Button } from "@/components";
import { useMutation } from "react-query";
interface Props {
  email: string;
}
export const Success = ({ email }: Props) => {
  const { isLoading, mutate } = useMutation(() => resendEmail(email), {
    onSuccess: () => {},
    onError: () => {},
  });

  return (
    <Box title="Check your email">
      <p>
        A verification letter has been sent your email address. Please check
        your inbox and click verification link to finish registration process.
      </p>
      <Button className="w-100" onClick={mutate} isLoading={isLoading}>
        Resend link
      </Button>
    </Box>
  );
};
