import React, { useMemo, useRef } from "react";
import { FaCaretDown } from "react-icons/fa";
import Select, { StylesConfig, components } from "react-select";

import "./style.scss";

interface Props {
  values?: {
    title: string;
    value?: string;
    id: any;
  }[];
  label?: string;
  className?: string;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  value?: string;
  onChange?(param: string): void;
  width?: number;
}

export const Select1: React.FC<Props> = ({
  className,
  values = [],
  value = "",
  onChange = () => {},
}) => {
  const actionRef = useRef<any>(null);

  // const handleChange = (param: any) => {
  //   actionRef.current && actionRef.current.blur();
  //   onChange(param);
  // };
  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaCaretDown />
      </components.DropdownIndicator>
    );
  };
  const colourStyles: StylesConfig<any> = {
    container: (styles) => ({ ...styles, width: "100%" }),
    control: (styles) => ({ ...styles, width: "100%" }),
    dropdownIndicator: (provided, state) => ({
      ...provided,
      transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
      transition: "transform 0.2s ease-in-out",
    }),
  };

  const innnerValue = useMemo(() => {
    const data = values.map((value) => ({
      ...value,
      value: value.id,
      label: value.title,
    }));
    if (value) {
      const existing = data.find((option) => option.value === value);
      if (existing) {
        return existing;
      }
    }
    if (actionRef.current) actionRef.current.clearValue();
    return undefined;
  }, [value, values]);

  const options = useMemo(() => {
    return values.map((value) => ({
      ...value,
      value: value.id,
      label: value.title,
    }));
  }, [values]);
  return (
    <Select
      defaultValue={undefined}
      className={className}
      options={options}
      styles={colourStyles}
      ref={actionRef}
      value={innnerValue}
      onChange={(option) => {
        if (option) {
          onChange(option?.value);
        }
      }}
      isSearchable={false}
      controlShouldRenderValue
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
    />
  );
};
