import { useAuth } from "@/contexts";
import { Navigate, Outlet } from "react-router-dom";

const AdminRoutes = () => {
  const auth = useAuth();

  if (!auth.isLoggedIn) {
    return <main>Loading...</main>;
  } else {
    if (auth.userInfo) {
      if (auth.userInfo.isAdmin) {
        return <Outlet />;
      } else {
        return <Navigate to="/task" />;
      }
    } else {
      return <Navigate to="/login" />;
    }
  }
};

export default AdminRoutes;
