import { API } from "@/utils/api";
import { UserPayload, LoginPayload, MfaLoginPayload } from "@/types";
import { GITHUB_OAUTH_CLIENT_ID } from "@/config";

export const login = async (loginPayload: LoginPayload) => {
  const response = await API({}, { Authorization: "" }).post(
    "login",
    loginPayload,
  );
  return response.data;
};

export const mfaLogin = async (loginPayload: MfaLoginPayload) => {
  const response = await API({}, { Authorization: "" }).post(
    "mfalogin",
    loginPayload,
  );
  localStorage.setItem("jwt", response.data.data.response);
  return response.data.data.response;
};

export const resendMfaCode = async (token: string) => {
  const response = await API({}, { Authorization: "" }).post("mfaresend", {
    token,
  });
  return response.data;
};

/* Sign up user */
export const signup = async (userPayload: UserPayload) => {
  const response = await API({}, { Authorization: "" }).post(
    "signup",
    userPayload,
  );
  return response.data;
};

/* Resend email while signup */
export const resendEmail = async (email: string) => {
  const response = await API({}, { Authorization: "" }).post(
    "signup/resendemail",
    { email },
  );
  return response.data;
};

/* Resend email while signup */
export const resendInviteEmail = async (email: string) => {
  const response = await API({}, { Authorization: "" }).post(
    "signup/resend_invite_email",
    { email },
  );
  return response.data;
};

/* Forgot Password */
export const forgotPassword = async (email: string) => {
  const response = await API({}, { Authorization: "" }).post("forgotpassword", {
    email,
  });
  return response.data;
};

/* Reset Password */
export const resetPassword = async (resetPasswordPayload: {
  token: string;
  password: string;
}) => {
  const response = await API({}, { Authorization: "" }).post(
    "resetpassword",
    resetPasswordPayload,
  );
  return response.data;
};

/* Reset Password */
export const resetPasswordAccount = async (resetPasswordPayload: {
  oldPassword: string;
  newPassword: string;
}) => {
  const response = await API().post(
    "resetpasswordaccount",
    resetPasswordPayload,
  );
  return response.data;
};

/* Reset Password */
export const loginGithub = async (code: string) => {
  const response = await API({}, { Authorization: "" }).post("logincode", {
    token: code,
    clientId: GITHUB_OAUTH_CLIENT_ID,
  });
  localStorage.setItem("jwt", response.data.data.response);
  return response.data.data.response;
};

/* Activate with token */
export const activateToken = async (token: string) => {
  const response = await API({}, { Authorization: "" }).post("activate", {
    token,
  });
  return response.data;
};

/* Activate team member */
export const activateTeamMember = async (token: string, password: string) => {
  const response = await API({}, { Authorization: "" }).post(
    "activate_team_member",
    {
      token,
      password,
    },
  );
  return response.data;
};

/* Regenerate token */
export const regenerateToken = async () => {
  const response = await API().get(`/user/_regenerate_token`);
  return response.data.data;
};

/* Delete user */
export const deleteUser = async (
  id: string,
  data: {
    reason: string;
    password: string;
  },
) => {
  const response = await API().delete(`/user/${id}`, { data });
  return response.data;
};
