/* eslint-disable react-hooks/exhaustive-deps */
import { resetPassword } from "@/api";
import { Box, Button, FormInput } from "@/components";
import { OnboardingLayout } from "@/layouts";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { IoLogIn } from "react-icons/io5";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  //   "Must contain at least one letter and number"
  // ),
  password2: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [error, setError] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    }

    return () => {};
  }, [isSuccess]);

  return (
    <OnboardingLayout>
      <div className="onboarding-title">
        <h3>Get Started</h3>
        <div>
          <span>Have an account?</span>
          <Link to="/login">
            <IoLogIn />
            Sign in
          </Link>
        </div>
      </div>

      <Box title="Reset Password">
        {isSuccess ? (
          <>
            <p>Your password has been successfully reset.</p>
            <p>Now directing to the login page...</p>
          </>
        ) : (
          <Formik
            initialValues={{
              password: "",
              password2: "",
            }}
            validationSchema={validationSchema}
            onSubmit={async (values) => {
              try {
                await resetPassword({
                  token: token!,
                  password: values.password!,
                });
                setIsSuccess(true);
              } catch (error: any) {
                setError(error.response.data.message);
              }
            }}
          >
            {({ isSubmitting }) => {
              return (
                <div>
                  <Form>
                    <FormInput
                      label="New Password"
                      type="password"
                      name="password"
                      isRequired
                      placeholder=""
                    />
                    <FormInput
                      label="Password"
                      type="password"
                      name="password2"
                      isRequired
                      placeholder=""
                      className="mt-3"
                    />

                    {error && (
                      <div className="text-left mt-3 color-red">{error}</div>
                    )}
                    <div className="mt-3">
                      <Button
                        type="submit"
                        color="primary"
                        className="w-100"
                        isLoading={isSubmitting}
                      >
                        Reset Password
                      </Button>
                    </div>
                  </Form>
                </div>
              );
            }}
          </Formik>
        )}
      </Box>
    </OnboardingLayout>
  );
};
