/* eslint-disable react-hooks/exhaustive-deps */
import {
  getUser,
  login as loginApi,
  loginGithub as loginGithubApi,
  resendEmail,
} from "@/api";
import { Box, Button, FormInput } from "@/components";
import { GITHUB_OAUTH_API } from "@/config";
import { useAuth, useData } from "@/contexts";
import { OnboardingLayout } from "@/layouts";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { IoLogIn, IoLogoGithub } from "react-icons/io5";
import { useMutation } from "react-query";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./style.scss";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Valid email is required")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  //   "Must contain at least one letter and number"
  // ),
});

const LoginPage = () => {
  const navigate = useNavigate();
  const { login } = useAuth();
  const { initData } = useData();
  const [error, setError] = useState("");
  const [email, setEmail] = useState("");
  useEffect(() => {
    const fetchData = async (code: string) => {
      try {
        await loginGithubApi(code);
        const userData = await getUser();
        login(userData);
        navigate(`/task`);
      } catch (error: any) {
        setError(error.response.data.message);
      }
    };
    const code =
      window.location.href.match(/\?code=(.*)/) &&
      window.location.href.match(/\?code=(.*)/)![1];
    if (code != null) {
      fetchData(code).catch(console.error);
    }
  }, []);

  const { isLoading: isResending, mutate: resendVerification } = useMutation(
    () => resendEmail(email),
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );

  return (
    <OnboardingLayout>
      <Helmet>
        <title>Login to your Sec3 account</title>
        <meta name="description" content="Sec3 Pro Login" />
      </Helmet>
      <div className="onboarding-title">
        <h3>Sign in</h3>
        <div>
          <span>Don't have an account? </span>
          <Link to="/">
            <IoLogIn />
            Sign up
          </Link>
        </div>
      </div>
      <Box title="Using email">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            setError("");
            setEmail(values.email);
            try {
              const loginResponse = await loginApi(values);
              if (loginResponse.data.response) {
                localStorage.setItem("jwt", loginResponse.data.response);
                initData();
                const userData = await getUser();
                login(userData);
                navigate(`/mfaprompt`);
              } else if (loginResponse.data.token) {
                const { mfaChannel, token } = loginResponse.data;
                navigate(`/mfa`, { state: { mfaChannel, token } });
              } else {
                setError("Unknown error");
                throw new Error("Unknown error");
              }
            } catch (error: any) {
              setError(error?.response?.data?.message || error.message);
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <div>
                <Form>
                  <FormInput
                    label="Email"
                    name="email"
                    isRequired
                    placeholder="admin@mycompany.com"
                    className="mt-3"
                  />
                  <div className="d-flex">
                    <Link to="/forgotpassword" className="forgot-password">
                      Forgot password?
                    </Link>
                  </div>
                  <FormInput
                    label="Password"
                    type="password"
                    name="password"
                    isRequired
                    placeholder="At least 8 characters with both letters and numbers"
                    className="login-password"
                  />

                  {error && (
                    <div className="d-flex">
                      <div className="text-left mt-3 color-red">{error}</div>
                      {error === "The user is not verified" && (
                        <div
                          className="resend-verification ms-3 mt-auto"
                          onClick={() => resendVerification()}
                        >
                          {isResending
                            ? "Resending..."
                            : "Resend verification link"}
                        </div>
                      )}
                    </div>
                  )}
                  <div className="mt-3">
                    <Button
                      type="submit"
                      color="primary"
                      className="w-100"
                      isLoading={isSubmitting}
                    >
                      Sign in
                    </Button>
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Box>
      <Box title="Sign in using GitHub" className="mt-4">
        <p>
          Run audits directly on your Solana projects from Github repository
        </p>
        <Button
          color="github"
          onClick={() => window.open(GITHUB_OAUTH_API, "_self")}
        >
          <IoLogoGithub size={32} />
          Connect
        </Button>
      </Box>
    </OnboardingLayout>
  );
};

export default LoginPage;
