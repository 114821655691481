import React from "react";
import classNames from "classnames";
import { useField, FieldHookConfig } from "formik";
import { nanoid } from "nanoid";
import "./style.scss";
type Props = FieldHookConfig<string> & {
  label?: string;
  className?: string;
  maxLength?: number;
  placeholder?: string;
  help?: string;
  isRequired?: boolean;
  disableAutoComplete?: boolean;
  isTouched?: boolean;
  disabled?: boolean;
};

export const FormTextArea: React.FC<Props> = ({
  label,
  className,
  help,
  placeholder,
  isRequired,
  maxLength,
  disableAutoComplete = false,
  isTouched = false,
  disabled = false,
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = event.target.value || "";
    helpers.setValue(maxLength ? value.slice(0, maxLength) : value);
  };
  const id = nanoid();
  return (
    <div
      className={classNames("form-textarea", className, {
        error: (isTouched || meta.touched) && meta.error,
      })}
    >
      {label && (
        <div className="d-flex justify-content-between">
          <label htmlFor={id} className="form-textarea__label">
            {label}
          </label>
          {!isRequired && (
            <div className="form-textarea__required">Optional</div>
          )}
        </div>
      )}
      <textarea
        value={field.value || ""}
        id={id}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete={"new-password"}
        disabled={disabled}
        className={classNames({
          active: field.value,
        })}
      />
      <div className="form-textarea__footer">
        {(isTouched || meta.touched) && meta.error && (
          <div className="form-textarea__validation">{meta.error}</div>
        )}
        {help && <div className="form-textarea__help ms-auto">{help}</div>}
      </div>
    </div>
  );
};
