import React from "react";
import classNames from "classnames";
import { useField, FieldHookConfig } from "formik";
import { nanoid } from "nanoid";
import "./style.scss";
type Props = FieldHookConfig<string> & {
  label?: string;
  type?: "text" | "password" | "email" | "number" | "phone";
  className?: string;
  maxLength?: number;
  placeholder?: string;
  help?: string;
  isRequired?: boolean;
  disableAutoComplete?: boolean;
  isTouched?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  id?: string;
};

export const FormInput: React.FC<Props> = ({
  label,
  className,
  help,
  type = "text",
  placeholder,
  isRequired,
  maxLength,
  disableAutoComplete = false,
  isTouched = false,
  disabled = false,
  autoFocus = false,
  id = "",
  ...props
}) => {
  const [field, meta, helpers] = useField(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value || "";
    if (type === "number") {
      value = value.replace(/[^\d]/g, "");
    }
    helpers.setValue(maxLength ? value.slice(0, maxLength) : value);
  };
  return (
    <div
      className={classNames("form-input", className, {
        error: (isTouched || meta.touched) && meta.error,
      })}
    >
      {label && (
        <div className="d-flex justify-content-between">
          <label htmlFor={id} className="form-input__label">
            {label}
          </label>
          {!isRequired && <div className="form-input__required">Optional</div>}
        </div>
      )}
      <input
        value={field.value || ""}
        id={id || nanoid()}
        onChange={handleChange}
        placeholder={placeholder}
        type={type}
        autoComplete={"new-password"}
        disabled={disabled}
        autoFocus={autoFocus}
        className={classNames({
          active: field.value,
        })}
      />
      <div className="form-input__footer">
        {(isTouched || meta.touched) && meta.error && (
          <div className="form-input__validation">{meta.error}</div>
        )}
        {help && <div className="form-input__help ms-auto">{help}</div>}
      </div>
    </div>
  );
};
