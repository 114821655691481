import { LOGIN, LOGOUT, UPDATE_USER_INFO } from "./../types";
import { AuthState } from "./index";

export default function AuthReducer(
  state: AuthState,
  action: { type: String; payload?: any },
): AuthState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isLoggedIn: true,
        userInfo: action.payload,
      };
    case UPDATE_USER_INFO:
      return {
        ...state,
        userInfo: { ...state.userInfo, ...action.payload },
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        userInfo: null,
      };

    default:
      return state;
  }
}
