import classNames from "classnames";
import { nanoid } from "nanoid";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import "./style.scss";

interface Props {
  label?: string;
  className?: string;
  maxLength?: number;
  placeholder?: string;
  isRequired?: boolean;
  disabled?: boolean;
  value?: string;
  autoFocus?: boolean;
  onBlur?(): void;
  onChange?(param: string): void;
}

export const Textarea = forwardRef(
  (
    {
      label,
      className,
      placeholder,
      maxLength,
      disabled = false,
      value = "",
      isRequired,
      onBlur = () => {},
      onChange = () => {},
    }: Props,
    ref,
  ) => {
    const inputRef = useRef<HTMLTextAreaElement>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        inputRef.current && inputRef.current.focus();
      },
    }));

    const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      const value = event.target.value || "";
      onChange(maxLength ? value.slice(0, maxLength) : value);
    };
    const id = nanoid();
    return (
      <div className={classNames("input-control", className)}>
        {label && (
          <div className="d-flex justify-content-between">
            <label htmlFor={id} className="input-control__label">
              {label}
            </label>
          </div>
        )}
        <textarea
          value={value || ""}
          id={id}
          onChange={handleChange}
          onBlur={onBlur}
          placeholder={placeholder}
          autoComplete={"new-password"}
          disabled={disabled}
          ref={inputRef}
          className={classNames(className, {
            active: value,
          })}
          required={isRequired}
        />
      </div>
    );
  },
);
