import React, { useMemo } from "react";
import { FaCaretDown } from "react-icons/fa";
import { Address } from "@/types";
import Select, { components } from "react-select";
import { reduceString } from "@/utils";
import "./style.scss";

interface Props {
  values?: Address[];
  label?: string;
  className?: string;
  placeholder?: string;
  isLoading?: boolean;
  disabled?: boolean;
  value?: Address;
  onChange?(param: Address): void;
  width?: number;
  isSearchable?: boolean;
  hasAll?: boolean;
}

export const AddressSelect: React.FC<Props> = ({
  values = [],
  placeholder,
  value,
  onChange = () => {},
  isSearchable = false,
  hasAll = true,
}) => {
  const handleChange = (param: any) => {
    onChange(param);
  };

  const formatOptionLabel = (option: any, context: any) => {
    return context.context === "menu" ? (
      <div className="address-select-item">
        {option.id ? (
          <>
            <div>
              <div className="address-select-item__alias">{option.alias}</div>

              <div className="address-select-item__address">
                {reduceString(option.address, 6)}
              </div>
            </div>
            <div className="address-select-item__network">
              <div>{option.chain}</div>
              <div>{option.network}</div>
            </div>
          </>
        ) : (
          <div>
            <div className="address-select-item__alias">{option.alias}</div>
          </div>
        )}
      </div>
    ) : (
      option.alias || option.address
    );
  };

  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FaCaretDown />
      </components.DropdownIndicator>
    );
  };

  const innerOptions = useMemo(() => {
    return hasAll
      ? [
          {
            alias: "All",
            id: "",
            chain: "",
            network: "",
            address: "",
            value: "",
          },
          ...values.map((value) => ({ ...value, value: value.id })),
        ]
      : [...values.map((value) => ({ ...value, value: value.id }))];
  }, [values]);

  return (
    <Select
      defaultValue={value}
      options={innerOptions}
      formatOptionLabel={formatOptionLabel}
      // menuPosition="fixed"
      placeholder={placeholder}
      onChange={handleChange}
      isSearchable={isSearchable}
      value={value}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
      styles={{
        dropdownIndicator: (provided, state) => ({
          ...provided,
          transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
          transition: "transform 0.2s ease-in-out",
        }),
      }}
    />
  );
};
