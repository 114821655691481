import { useAuth } from "@/contexts";
import { Navigate, Outlet } from "react-router-dom";

export const PublicRoutes = () => {
  const auth = useAuth();
  return auth.userInfo && localStorage.getItem("jwt") ? (
    <Navigate to="/task" />
  ) : (
    <Outlet />
  );
};
