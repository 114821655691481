import sec3Logo from "@/assets/imgs/sec3-01.svg";
import NavOutIcon from "@/assets/nav-out.svg";
import { useAuth } from "@/contexts";
import { IoLogIn } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";
import "./style.scss";

export const MobileOnboardingHeader = () => {
  const location = useLocation();
  const { userInfo, logout } = useAuth();
  return (
    <div className="mobile-onboarding-header">
      <Link to="/">
        <img
          src={sec3Logo}
          alt="logo"
          className="mobile-onboarding-header__logo"
        />
      </Link>
      {userInfo ? (
        <span onClick={logout} className="mobile-onboarding-header__signout">
          <img src={NavOutIcon} alt={"sign out"} />
          <span>Sign out</span>
        </span>
      ) : (
        <Link
          to={location.pathname === "/" ? "/login" : "/"}
          className="mobile-onboarding-header__login"
        >
          <IoLogIn size={20} />
          {location.pathname === "/" ? (
            <span>Sign in</span>
          ) : (
            <span>Sign up</span>
          )}
        </Link>
      )}
    </div>
  );
};
