import {
  getInsightsCategorical,
  getInsightsTimeSeries,
  getInsightsTotal,
} from "@/api";
import { FigureTable } from "@/components";
import { useMonitor } from "@/contexts";
import { reduceString, subDateFromNow } from "@/utils";
import { format } from "date-fns";
import ReactEcharts from "echarts-for-react";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import "./style.scss";

interface Props {
  metric: string;
  queryName?: string;
  address?: string;
  property: string;
  chartType: string;
  timeRange: string;
  interval: string;
  aggregate?: string;
  groupBy?: string;
  onDataUpdate?(data: any): void;
  isFullScreen?: boolean;
  text?: string;
  textColor?: string;
  backgroundColor?: string;
  showArchived?: boolean;
}

export const FigureGraph = ({
  metric,
  queryName,
  address,
  property,
  chartType,
  timeRange,
  interval,
  aggregate = "count",
  groupBy,
  onDataUpdate = () => {},
  isFullScreen,
  text,
  textColor,
  backgroundColor,
  showArchived,
}: Props) => {
  const { currentProjectId } = useMonitor();
  const [visualData, setVisualData] = useState<any[] | undefined>();

  const { isLoading, isFetching } = useQuery<any[], Error>(
    [
      "figure-graph",
      metric,
      queryName,
      chartType,
      address,
      property,
      timeRange,
      interval,
      aggregate,
      groupBy,
      showArchived,
    ],
    () => fetchData(),
    {
      keepPreviousData: true,
      enabled: !!metric && !!queryName,
      onError: () => {},
      onSuccess: async (data) => {
        setVisualData(data);
        onDataUpdate(data);
      },
      // refetchInterval: 3000,
      // refetchIntervalInBackground: true,
      // refetchOnWindowFocus: true,
    },
  );

  useEffect(() => {
    return () => {};
  }, [chartType]);

  const fetchData = async () => {
    const to = Math.floor(new Date().getTime() / 1000);
    const from = Math.floor(subDateFromNow(timeRange).getTime() / 1000);
    let filters: {
      filterBy: string;
      filterer: any;
    }[] = address ? [{ filterBy: "address", filterer: address }] : [];

    if (["alerts", "bots"].includes(metric)) {
      filters = [
        {
          filterBy: "projectId",
          filterer: currentProjectId,
        },
      ];
      if (address) {
        filters.push({
          filterBy: "addressId",
          filterer: address,
        });
      }
    }
    if (metric === "alerts" && !showArchived) {
      filters.push({
        filterBy: "isArchived",
        filterer: "false",
      });
    }

    let result;
    if (chartType === "line" || chartType === "bar" || chartType === "area") {
      const res = await getInsightsTimeSeries({
        metric,
        interval,
        filters,
        from,
        to,
        aggregate,
      });

      result = res.data.data.map((item: any) => ({
        ...item,
        x: format(
          new Date(item.x),
          interval === "1m"
            ? "MMM dd HH:mm"
            : interval === "1h"
              ? "MMM dd HH"
              : "MMM dd",
        ),
      }));
    } else if (chartType === "pie" && groupBy) {
      const res = await getInsightsCategorical({
        metric,
        filters,
        from,
        to,
        aggregate,
        categorizedBy: groupBy,
      });
      result = res.data.data;
    } else if (chartType === "value") {
      const res = await getInsightsTotal({
        metric,
        filters,
        from,
        to,
        aggregate,
      });
      result = res.data.data;
    }
    return result;
  };

  if (text) {
    return <div className="figure-graph-text">{text}</div>;
  }
  if (isLoading || isFetching) {
    return <div className="figure-graph-loading">Loading...</div>;
  }
  if (chartType === "table") {
    return (
      <FigureTable
        metric={metric}
        address={address}
        timeRange={timeRange}
        onDataUpdate={onDataUpdate}
        isFullScreen={isFullScreen}
        showArchived={showArchived}
      />
    );
  }
  if (Array.isArray(visualData)) {
    if (visualData.length === 0) {
      return <div className="m-auto">No {metric}</div>;
    }
    return chartType === "line" || chartType === "area" ? (
      <ReactEcharts
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
          backgroundColor,
          borderRadius: 6,
        }}
        option={{
          tooltip: {
            trigger: "axis",
            position: function (pt: any) {
              return [pt[0], "10%"];
            },
          },
          grid: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
            containLabel: true,
          },
          xAxis: {
            name: "date",
            type: "category",
            boundaryGap: true,
            data: visualData.map((item) => item.x),
            axisLabel: {
              color: backgroundColor !== "white" ? "white" : "#333",
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              color: backgroundColor !== "white" ? "white" : "#333",
            },
          },
          series: [
            {
              name: aggregate,
              data: visualData.map((item) => item.y),
              type: "line",
              areaStyle: chartType === "area" ? {} : undefined,
              lineStyle: {
                color: textColor,
              },
              itemStyle: {
                color: textColor,
              },
            },
          ],
        }}
      />
    ) : chartType === "bar" ? (
      <ReactEcharts
        style={{
          height: "100%",
          width: "100%",
          flex: 1,
          backgroundColor,
          borderRadius: 6,
        }}
        option={{
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid: {
            top: 10,
            bottom: 10,
            left: 10,
            right: 10,
            containLabel: true,
          },
          xAxis: {
            name: "date",
            type: "category",
            boundaryGap: true,
            data: visualData.map((item) => item.x),
            axisLabel: {
              color: backgroundColor !== "white" ? "white" : "#333",
            },
          },
          yAxis: {
            type: "value",
            axisLabel: {
              color: backgroundColor !== "white" ? "white" : "#333",
            },
          },
          series: [
            {
              name: aggregate,
              data: visualData.map((item) => item.y),
              type: "bar",
              lineStyle: {
                color: textColor,
              },
              itemStyle: {
                color: textColor,
              },
            },
          ],
        }}
      />
    ) : chartType === "pie" ? (
      <ReactEcharts
        style={{ height: "100%", width: "100%", flex: 1 }}
        option={{
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: "5%",
            left: "center",
          },
          series: [
            {
              name: { groupBy },
              type: "pie",
              radius: ["40%", "70%"],
              avoidLabelOverlap: false,
              itemStyle: {
                borderRadius: 10,
                borderColor: "#fff",
                borderWidth: 2,
              },
              label: {
                show: false,
                position: "center",
              },
              emphasis: {
                label: {
                  show: true,
                  fontSize: 40,
                  fontWeight: "bold",
                },
              },
              labelLine: {
                show: false,
              },
              data: visualData.map((item) => ({
                value: item.y,
                name: reduceString(item.x, 4),
              })),
            },
          ],
        }}
      />
    ) : null;
  } else if (chartType === "value" && (visualData || visualData === 0)) {
    return (
      <div
        className="figure-graph-value"
        style={{
          backgroundColor: backgroundColor,
          color: textColor,
        }}
      >
        {visualData > 10000
          ? numeral(visualData).format("0.00a").toUpperCase()
          : numeral(visualData).format("0,0")}
      </div>
    );
  } else {
    return <div></div>;
  }
};
