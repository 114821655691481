import React from "react";
import { IoMdClose } from "react-icons/io";
import AddressIcon from "@/assets/address-icon.svg";
import BotIcon from "@/assets/bot-icon.svg";
import { Modal as ModalWrapper } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./style.scss";
interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  title?: string;
  disableOutsideClick?: boolean;
  disableClose?: boolean;
  children: React.ReactNode;
  icon?: "address" | "bot";
  size?: "large" | "small" | "normal";
  onClose?(): void;
  className?: string;
  styles?: React.CSSProperties;
}
export const Modal = ({
  open,
  title,
  setOpen,
  disableOutsideClick = false,
  children,
  icon,
  size = "normal",
  className = "",
  styles,
  onClose = () => {},
}: Props) => {
  return (
    <ModalWrapper
      open={open}
      onClose={() => {
        onClose();
        setOpen(false);
      }}
      center
      closeOnOverlayClick={!disableOutsideClick}
      classNames={{ modal: `modal2-container ${size} ${className}` }}
      styles={{ modal: styles }}
      focusTrapped={false}
      closeIcon={
        <span className="modal2__close">
          <IoMdClose size={24} />
        </span>
      }
    >
      <div className="modal2__header">
        {icon === "address" ? (
          <img src={AddressIcon} alt="address" className="modal2__icon" />
        ) : icon === "bot" ? (
          <img src={BotIcon} alt="bot" className="modal2__icon" />
        ) : null}
        <div className={`my-auto title  w-100`}>{title}</div>
      </div>
      <div className="modal2__content">{children}</div>
    </ModalWrapper>
  );
};
