/* eslint-disable react-hooks/exhaustive-deps */
import { getInsightsEvent } from "@/api";
import { useMonitor } from "@/contexts";
import { subDateFromNow } from "@/utils";
import { GridApi } from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component
import { format } from "date-fns";
import { useEffect, useMemo, useRef, useState } from "react";
import "./style.scss";
interface Props {
  metric: string;
  address?: string;
  timeRange: string;
  showArchived?: boolean;
  onDataUpdate?(data: any): void;
  isFullScreen?: boolean;
}

export const FigureTable = ({
  metric,
  address,
  timeRange,
  showArchived,
  onDataUpdate = () => {},
  isFullScreen,
}: Props) => {
  const gridRef = useRef<AgGridReact<any>>(null);
  const [gridApi, setGridApi] = useState<GridApi>();
  const { metricData, currentProjectId } = useMonitor();
  const perPage = 10;

  const columns = useMemo(() => {
    if (metricData) {
      const item = metricData.find((item) => item.name === metric);
      if (item) {
        return [
          ...item.fields.map((item) => ({ field: item.name })),
          { field: "time", headerName: "Timestamp" },
        ];
      }
    }
    return [];
  }, [metricData, metric]);

  const onGridReady = (params: any) => {
    setGridApi(params.api);
  };
  useEffect(() => {
    if (gridApi) {
      const dataSource = {
        getRows: (params: any) => {
          const to = Math.floor(new Date().getTime() / 1000);
          const from = Math.floor(subDateFromNow(timeRange).getTime() / 1000);
          let filters: {
            filterBy: string;
            filterer: string;
          }[] = [];

          if (["alerts", "bots"].includes(metric)) {
            filters = [
              {
                filterBy: "projectId",
                filterer: currentProjectId,
              },
            ];
            if (address) {
              filters.push({
                filterBy: "addressId",
                filterer: address,
              });
            }
          }
          if (metric === "alerts" && !showArchived) {
            filters.push({
              filterBy: "isArchived",
              filterer: "false",
            });
          }
          let sortBy = "created_at";
          let sortOrder = 1;
          if (params.sortModel.length > 0) {
            sortBy = params.sortModel[0].colId;
            sortOrder = params.sortModel[0].sort === "asc" ? 1 : -1;
          }
          getInsightsEvent({
            metric,
            filters,
            from,
            to,
            offset: params.endRow - perPage,
            size: perPage,
            sortBy,
            sortOrder,
          })
            .then((res) => {
              const result = res.data.data.map((item: any) => ({
                ...item.y,
                time: format(new Date(item.x), "yyyy-MM-dd HH:mm:ss"),
              }));
              onDataUpdate(result);
              params.successCallback(result, res.data.count);
            })
            .catch(() => {
              params.successCallback([], 0);
            });
        },
      };

      gridApi.setDatasource(dataSource);
    }
  }, [gridApi, metric, address, timeRange, showArchived]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(
    () => ({
      sortable: true,
      resizable: true,
      flex: 1,
    }),
    [],
  );

  return (
    <div
      className="ag-theme-alpine"
      style={{
        width: "100%",
        height: isFullScreen ? "calc(100vh - 200px)" : "100%",
      }}
    >
      <AgGridReact
        ref={gridRef} // Ref for accessing Grid's API
        columnDefs={columns} // Column Defs for Columns
        defaultColDef={defaultColDef} // Default Column Properties
        animateRows={true} // Optional - set to 'true' to have rows animate when sorted
        rowSelection="multiple" // Options - allows click selection of rows
        pagination={true}
        rowModelType={"infinite"}
        paginationPageSize={perPage}
        cacheBlockSize={perPage}
        onGridReady={onGridReady}
      />
    </div>
  );
};
