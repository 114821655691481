import React from "react";
import classNames from "classnames";
import "./style.scss";

interface Props {
  className?: string;
  label?: string;
  value: boolean;
  onChange(params: boolean): void;
  disabled?: boolean;
}

export const Switch: React.FC<Props> = ({
  className,
  value,
  label,
  onChange = () => {},
  disabled = false,
}) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.checked;
    onChange(value);
  };
  return (
    <div className={classNames(className)}>
      <label className="toggle">
        <input
          type="checkbox"
          checked={value}
          onChange={handleChange}
          disabled={disabled}
          className="toggle-checkbox"
        />
        <div className="toggle-switch"></div>
        <span className="toggle-label">{label}</span>
      </label>
    </div>
  );
};
