import React, { useRef } from "react";
import classNames from "classnames";
import ScaleLoader from "react-spinners/ScaleLoader";
import {
  FaPlus,
  FaPlay,
  FaFileDownload,
  FaFolder,
  FaArchive,
  FaPause,
  FaBell,
} from "react-icons/fa";
import { HiPencil } from "react-icons/hi";
import { GiRoundStar } from "react-icons/gi";
import { FiRefreshCw } from "react-icons/fi";
import { MdUnarchive } from "react-icons/md";
import { BsGithub, BsInfoCircle } from "react-icons/bs";
import { RiRobotFill } from "react-icons/ri";
import { Tooltip } from "react-tooltip";
import { nanoid } from "nanoid";

import "./styles.scss";
import "react-tooltip/dist/react-tooltip.css";

interface Props {
  className?: string;
  icon?:
    | "plus"
    | "play"
    | "pause"
    | "github"
    | "download"
    | "refresh"
    | "folder"
    | "info"
    | "star"
    | "archive"
    | "unarchive"
    | "bell"
    | "edit"
    | "bot";
  type?: "button" | "submit" | "reset" | undefined;
  color?:
    | "primary"
    | "secondary"
    | "light"
    | "red"
    | "github"
    | "light-purple"
    | "green"
    | "light-red"
    | "transparent"
    | "white"
    | "gray"
    | "dark-red"
    | undefined;
  theme?: "outline" | "normal";
  shape?: "rounded" | "circle";
  size?: "small" | "medium" | "large";
  block?: boolean;
  disabled?: boolean;
  children: React.ReactNode;
  onClick?: React.EventHandler<any>;
  isLoading?: boolean;
  hideContentMobile?: boolean;
  hideMobile?: boolean;
  disabledTooltip?: React.ReactNode;
  id?: string;
}

export const Button = ({
  className,
  color = "primary",
  disabled = false,
  disabledTooltip,
  type = "button",
  children,
  onClick,
  isLoading = false,
  icon,
  block = false,
  hideContentMobile,
  hideMobile,
  size = "medium",
  theme = "normal",
  shape = "circle",
  id = "",
}: Props) => {
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipId = nanoid();
  return (
    <>
      <button
        className={classNames(
          "button-control",
          className,
          color,
          size,
          theme,
          shape,
          {
            hideMobileContent: hideContentMobile,
            hideMobile: hideMobile,
            block,
          },
        )}
        disabled={disabled}
        type={type}
        onClick={onClick}
        id={id}
      >
        {isLoading && (
          <ScaleLoader
            cssOverride={{
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            color={"white"}
            height={size === "small" ? 20 : 35}
            width={size === "small" ? 2 : 4}
            radius={size === "small" ? 2 : 3}
            margin={size === "small" ? 2 : 3}
          />
        )}
        <div
          ref={tooltipRef}
          data-place="bottom"
          data-tooltip-id={tooltipId}
          className={`btn-content ${isLoading && "hidden-btn"}`}
        >
          {icon === "plus" ? (
            <FaPlus size={16} color="white" />
          ) : icon === "play" ? (
            <FaPlay size={16} color="white" />
          ) : icon === "pause" ? (
            <FaPause size={16} color="white" />
          ) : icon === "bell" ? (
            <FaBell size={16} color="white" />
          ) : icon === "download" ? (
            <FaFileDownload size={16} color="white" />
          ) : icon === "refresh" ? (
            <FiRefreshCw size={16} color="white" />
          ) : icon === "github" ? (
            <BsGithub size={20} color="white" />
          ) : icon === "folder" ? (
            <FaFolder size={14} color="white" />
          ) : icon === "star" ? (
            <GiRoundStar size={24} />
          ) : icon === "info" ? (
            <BsInfoCircle size={24} color="white" />
          ) : icon === "edit" ? (
            <HiPencil size={24} color="white" />
          ) : icon === "archive" ? (
            <FaArchive size={18} color="#9D1E1B" />
          ) : icon === "unarchive" ? (
            <MdUnarchive size={18} color="#9D1E1B" />
          ) : icon === "bot" ? (
            <RiRobotFill size={16} color="white" />
          ) : null}
          <span>{children}</span>
        </div>
      </button>
      {disabledTooltip && disabled && (
        <Tooltip
          id={tooltipId}
          // effect="solid"
          className="button-tooltip"
          place="bottom"
          // html={disabledTooltip}
          render={() => (
            <div className="button-tooltip__content">{disabledTooltip}</div>
          )}
        />
      )}
    </>
  );
};
