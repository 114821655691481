import { addInsightsWidget } from "@/api";
import { DropdownMenu, DropdownMenuItem, FigureGraph } from "@/components";
import { useMonitor } from "@/contexts";
import { useModal } from "@/hooks";
import { DeleteFigureModal, FullFigureModal } from "@/modals";
import { InsightWidget } from "@/types";
import { toPng, toSvg } from "html-to-image";
import { useCallback, useRef, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useMutation } from "react-query";
import "./style.scss";

interface Props {
  data: InsightWidget;
  onEdit?: (data: InsightWidget) => void;
}

export const Figure = ({ data, onEdit = () => {} }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const { currentProjectId, setCurrentInsightsLayout, currentInsightsLayout } =
    useMonitor();
  const fullscreenModal = useModal();
  const deleteModal = useModal();
  const [result, setResult] = useState<any>();

  const { mutate: createFigure } = useMutation(
    (widget: InsightWidget) => addInsightsWidget(currentProjectId, widget),
    {
      onSuccess: (res) => {
        setCurrentInsightsLayout([...currentInsightsLayout, res.data]);
      },
      onError: () => {},
    },
  );

  const handleFullScreen = () => {
    fullscreenModal.setOpen(true);
  };

  const handleEdit = () => {
    onEdit(data);
  };
  const handleClone = () => {
    createFigure({
      name: data.name,
      x: data.x,
      y: data.y,
      width: data.width,
      height: data.height,
      queries: data.queries,
    });
  };

  const handleDelete = () => {
    deleteModal.setOpen(true);
  };

  const handeExport = useCallback(
    (type: string) => {
      if (ref.current === null) {
        return;
      }
      switch (type) {
        case "png":
          toPng(ref.current, { cacheBust: true })
            .then((dataUrl) => {
              const link = document.createElement("a");
              link.download = `${data.name}.png`;
              link.href = dataUrl;
              link.click();
            })
            .catch((err) => {
              console.log(err);
            });

          break;
        case "svg":
          toSvg(ref.current, { cacheBust: true })
            .then((dataUrl) => {
              const link = document.createElement("a");
              link.download = `${data.name}.svg`;
              link.href = dataUrl;
              link.click();
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        default:
          break;
      }
    },
    [ref, data.name],
  );

  const handleDownloadCSV = () => {
    console.log("download csv", result);

    let csvContent = "";
    if (Array.isArray(result) && result.length > 0) {
      const firstRow = result[0];
      csvContent = "data:text/csv;charset=utf-8,";
      csvContent += Object.keys(firstRow).join(",") + "\n";

      csvContent += result.map((e) => Object.values(e).join(",")).join("\n");
    } else if (result) {
      csvContent = "data:text/csv;charset=utf-8," + result.toString();
    }
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${data.name}.csv`);
    document.body.appendChild(link);

    link.click();
  };

  return (
    <div className="insights-figure">
      <div className="insights-figure__header">
        <div className="insights-figure__header-title">{data.name}</div>
        <div className="ms-auto">
          <DropdownMenu
            label={
              <span
                className="insights-figure__settings"
                onClick={(e) => e.preventDefault()}
              >
                <BsThreeDotsVertical />
              </span>
            }
          >
            <DropdownMenuItem label="Full Screen" onClick={handleFullScreen} />

            <DropdownMenu label={"Export"}>
              <DropdownMenuItem
                onClick={() => handeExport("png")}
                label="Export as PNG"
              />
              <DropdownMenuItem
                onClick={() => handeExport("svg")}
                label="Export as SVG"
              />
              <DropdownMenuItem
                onClick={() => handleDownloadCSV()}
                label="Export as CSV"
              />
            </DropdownMenu>
            <DropdownMenuItem label="Edit" onClick={handleEdit} />
            <DropdownMenuItem label="Clone" onClick={handleClone} />
            <DropdownMenuItem label="Delete" onClick={handleDelete} />
          </DropdownMenu>
        </div>
      </div>
      <div ref={ref} className="insights-figure__content">
        <FigureGraph
          metric={data.queries[0].metric}
          queryName={data.queries[0].queryName}
          address={data.queries[0].addressId}
          property={data.queries[0].property}
          chartType={data.queries[0].chartType}
          timeRange={data.queries[0].timeRange}
          interval={data.queries[0].interval}
          aggregate={data.queries[0].aggregate}
          groupBy={data.queries[0].groupBy}
          showArchived={data.queries[0].showArchived}
          textColor={data.textColor}
          backgroundColor={data.backgroundColor}
          onDataUpdate={(data) => {
            setResult(data);
          }}
        />
      </div>
      <FullFigureModal {...fullscreenModal} title={data.name} data={data} />
      <DeleteFigureModal {...deleteModal} data={data} />
    </div>
  );
};
