import React from "react";
import sec3Logo from "@/assets/imgs/sec3-01.svg";
import { AiFillHome } from "react-icons/ai";
import { MobileOnboardingHeader } from "@/components";
import "./style.scss";
import {
  ONBOARDING_TEXT,
  ONBOARDING_TITLE,
  ONBOARDING_SUBTITLE,
} from "@/config";
interface Props {
  children: React.ReactNode;
}

export function OnboardingLayout({ children }: Props) {
  return (
    <div className="onboarding-layout">
      <section className="onboarding-layout__left">
        <div className="onboarding-layout__content">
          <img
            className="onboarding-layout__logo"
            src={sec3Logo}
            alt="sec3 logo"
          />
          <h1 className="onboarding-layout__title">{ONBOARDING_TITLE}</h1>
          <h2 className="onboarding-layout__subtitle">{ONBOARDING_SUBTITLE}</h2>
          <p className="onboarding-layout__desc">{ONBOARDING_TEXT}</p>

          <a
            className="onboarding-layout__back-to-home"
            href="https://sec3.dev"
          >
            <AiFillHome size={24} />
            <span>Back to homepage</span>
          </a>
        </div>
      </section>
      <MobileOnboardingHeader />
      <section className="onboarding-layout__right">{children}</section>
    </div>
  );
}
