import { FaCaretDown } from "react-icons/fa";
import Select2, { StylesConfig, components } from "react-select";
export interface ColourOption {
  readonly value: string;
  readonly label: string;
  readonly backgroundColor: string;
  readonly textColor: string;
}

const colourOptions: readonly ColourOption[] = [
  {
    value: "Blue",
    label: "Blue",
    backgroundColor: "white",
    textColor: "#2e49bc",
  },
  {
    value: "Grey",
    label: "Grey",
    backgroundColor: "white",
    textColor: "#919191",
  },
  {
    value: "Red",
    label: "Red",
    backgroundColor: "white",
    textColor: "#df2f2b",
  },
  {
    value: "Yellow",
    label: "Yellow",
    backgroundColor: "white",
    textColor: "#f79c05",
  },
  {
    value: "Green",
    label: "Green",
    backgroundColor: "white",
    textColor: "#00875A",
  },
  {
    value: "Red background",
    label: "Red background",
    backgroundColor: "#df2f2b",
    textColor: "white",
  },
  {
    value: "Yellow background",
    label: "Yellow background",
    backgroundColor: "#f79c05",
    textColor: "white",
  },
  {
    value: "Green background",
    label: "Green background",
    backgroundColor: "#00875A",
    textColor: "white",
  },
  {
    value: "Blue background",
    label: "Blue background",
    backgroundColor: "#2e49bc",
    textColor: "white",
  },
];

const colourStyles: StylesConfig<ColourOption> = {
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isSelected }) => {
    return {
      ...styles,
      backgroundColor: data.backgroundColor,
      color: data.textColor,
      cursor: "pointer",
      border: isSelected
        ? "1px solid #2e49bc"
        : `1px solid ${data.backgroundColor}`,
      position: "relative",

      ":active": {
        ...styles[":active"],
        backgroundColor: data.backgroundColor,
      },
      ":after": isSelected
        ? {
            backgroundColor: "transparent",
            content: '" "',
            position: "absolute",
            right: 16,
            top: 10,
            width: 6,
            height: 12,
            display: "block",
            border: "solid #2e49bc",
            borderWidth: "0 2px 2px 0",
            "-webkit-transform": "rotate(45deg)",
            "-ms-transform": "rotate(45deg)",
            transform: "rotate(45deg)",
          }
        : {},
      ":hover": {
        border: "1px solid #2e49bc",
      },
    };
  },
  input: (styles) => ({ ...styles }),
  placeholder: (styles) => ({ ...styles }),
  singleValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.backgroundColor,
    color: data.textColor,
    padding: 0,
    paddingLeft: 8,
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "",
    transition: "transform 0.2s ease-in-out",
  }),
  menuList: (styles) => ({ ...styles, maxHeight: 200 }),
};

interface Props {
  value: {
    textColor: string;
    backgroundColor: string;
  };
  onChange: (value: { textColor: string; backgroundColor: string }) => void;
}
const DropdownIndicator = (props: any) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaCaretDown />
    </components.DropdownIndicator>
  );
};
export const ColorThemeSelect = ({ value, onChange }: Props) => {
  return (
    <Select2<ColourOption>
      defaultValue={colourOptions[1]}
      options={colourOptions}
      styles={colourStyles}
      value={colourOptions.find(
        (option) =>
          option.textColor === value.textColor &&
          option.backgroundColor === value.backgroundColor,
      )}
      onChange={(option) => {
        if (option) {
          onChange({
            textColor: option?.textColor,
            backgroundColor: option?.backgroundColor,
          });
        }
      }}
      isSearchable={false}
      components={{ DropdownIndicator, IndicatorSeparator: () => null }}
    />
  );
};
