import React from "react";
import classNames from "classnames";
import "./style.scss";
interface Props {
  className?: string;
  title?: string;
  children?: React.ReactNode;
}

export const Box = ({ className = "", title, children }: Props) => {
  return (
    <div className={classNames("box-control", className)}>
      {title && <h5>{title}</h5>}
      {children}
    </div>
  );
};
