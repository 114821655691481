import React from "react";
import classNames from "classnames";
import useOnclickOutside from "react-cool-onclickoutside";
import { IoMdClose } from "react-icons/io";
import "./style.scss";
interface Props {
  open: boolean;
  setOpen(open: boolean): void;
  title?: string;
  disableOutsideClick?: boolean;
  disableClose?: boolean;
  children: React.ReactNode;
  icon?: "address" | "bot";
  size?: "large" | "small" | "normal";
  onClose?(): void;
}
export const SidePanel = ({
  open,
  title,
  setOpen,
  disableOutsideClick = false,
  disableClose = false,
  children,
  size = "normal",
  onClose = () => {},
}: Props) => {
  const ref = useOnclickOutside(() => {
    if (!disableOutsideClick) {
      // setOpen(false);
      // onClose();
    }
  });
  return (
    <div
      className={classNames("side-panel-container", {
        open,
      })}
      onClick={() => {
        setOpen(false);
        onClose();
      }}
    >
      <div
        ref={ref}
        tabIndex={-1}
        className={classNames(
          "side-panel",
          size,
          open,
          open ? "slide-in" : "slide-out",
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <div className="side-panel__header">
          <div className={`my-auto title  w-100`}>{title}</div>
          {!disableClose && (
            <div
              className="close"
              onClick={() => {
                setOpen(false);
                onClose();
              }}
            >
              <IoMdClose size={24} />
            </div>
          )}
        </div>
        <div className="side-panel__content">{children}</div>
      </div>
    </div>
  );
};
