import React from "react";
import useDarkMode from "use-dark-mode";
const lightTheme = "light-mode";
const darkTheme = "dark-mode";

export const useTheme = () => {
  const darkMode = useDarkMode(false, {
    element: undefined,
  });
  const [theme, setTheme] = React.useState(lightTheme);
  React.useEffect(() => {
    setTheme(darkMode?.value ? lightTheme : darkTheme);
  }, [darkMode.value]);

  return theme;
};
