import { useState } from "react";
import { Link } from "react-router-dom";
import { IoLogIn, IoLogoGithub } from "react-icons/io5";
import { OnboardingLayout } from "@/layouts";
import { Button, FormCheckbox, FormInput, Box } from "@/components";
import { Formik, Form } from "formik";
import { signup } from "@/api";
import * as Yup from "yup";
import { Success } from "./Success";
import { GITHUB_OAUTH_API } from "@/config";
import "./style.scss";

const validationSchema = Yup.object().shape({
  organization: Yup.string().required("Organization is required"),
  username: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Valid email is required")
    .required("Email is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
  // .matches(
  //   /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/,
  //   "Must contain at least one letter and number"
  // ),
  agreement: Yup.bool().oneOf(
    [true],
    "Must accept terms of service and privacy policy.",
  ),
});

const SignupPage = () => {
  const [error, setError] = useState("");
  const [isSignupSuccess, setIsSignupSuccess] = useState(false);
  const [email, setEmail] = useState("");

  return (
    <>
      <div className="signup-page">
        <OnboardingLayout>
          <div className="onboarding-title">
            <h3>Get Started</h3>
            <div>
              <span>Have an account?</span>
              <Link to="/login">
                <IoLogIn />
                Sign in
              </Link>
            </div>
          </div>
          {isSignupSuccess ? (
            <Success email={email} />
          ) : (
            <>
              <Box title="Sign up using email">
                <Formik
                  initialValues={{
                    username: "",
                    organization: "",
                    email: "",
                    password: "",
                    agreement: false,
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values: any) => {
                    setError("");
                    try {
                      setEmail(values.email);
                      await signup({
                        username: values.username,
                        organization: values.organization,
                        email: values.email,
                        password: values.password,
                      });
                      setIsSignupSuccess(true);
                    } catch (error: any) {
                      setError(error.response.data.message);
                    }
                  }}
                >
                  {({ isSubmitting }) => {
                    return (
                      <div>
                        <Form>
                          <FormInput
                            label="Organization"
                            name="organization"
                            isRequired
                            placeholder="My Company"
                            className="mt-3"
                            id="signup-organization"
                          />
                          <FormInput
                            label="Name for Billing"
                            name="username"
                            isRequired
                            placeholder="Name for Billing"
                            className="mt-3"
                            id="signup-username"
                          />
                          <FormInput
                            label="Email"
                            name="email"
                            isRequired
                            placeholder="admin@mycompany.com"
                            className="mt-3"
                            id="signup-email"
                          />
                          <FormInput
                            label="Password"
                            type="password"
                            name="password"
                            isRequired
                            placeholder="At least 8 characters with both letters and numbers"
                            className="mt-3"
                            id="signup-password"
                          />

                          <FormCheckbox
                            name="agreement"
                            id="signup-accept"
                            className="mt-3"
                          >
                            I accept the{" "}
                            <a
                              href="https://sec3.dev/terms-and-conditions"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <u>terms of service</u>
                            </a>{" "}
                            and{" "}
                            <a
                              href="https://sec3.dev/privacy-policy"
                              rel="noreferrer"
                              target="_blank"
                            >
                              <u>privacy policy</u>
                            </a>
                            <div className="invalid-feedback">
                              You must agree the terms of service and privacy
                              policy to continue
                            </div>
                          </FormCheckbox>

                          {error && (
                            <div className="text-left mt-3 color-red">
                              {error}
                            </div>
                          )}
                          <div className="mt-3">
                            <Button
                              type="submit"
                              color="primary"
                              className="w-100"
                              isLoading={isSubmitting}
                              id="signup-submit"
                            >
                              Continue
                            </Button>
                          </div>
                        </Form>
                      </div>
                    );
                  }}
                </Formik>
              </Box>
              <Box title="Sign up using GitHub" className="mt-4">
                <p>
                  Run audits directly on your Solana projects from Github
                  repository
                </p>
                <Button
                  color="github"
                  onClick={() => window.open(GITHUB_OAUTH_API, "_self")}
                >
                  <IoLogoGithub size={32} />
                  Connect
                </Button>
              </Box>
            </>
          )}
        </OnboardingLayout>
      </div>
      {/* <Mobile /> */}
    </>
  );
};

export default SignupPage;
