import axios from "axios";
import { API_URL, API_VERSION } from "@/config";

import { setupCache } from "axios-cache-adapter";

const API = (opts = {}, optsHeader = {}) => {
  /*
  |--------------------------------------------------
  | Custom axios api
  |--------------------------------------------------
  */
  const token = localStorage.getItem("jwt");
  const cache = setupCache({
    maxAge: 15 * 60 * 1000,
  });
  const defaultOptions = {
    ...opts,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
      ...optsHeader,
    },
    mode: "cors", // no-cors, *cors, same-origin
    // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  };

  const baseURL = `${API_URL}/${API_VERSION}`;
  const axiosApi = axios.create({
    baseURL,
    ...defaultOptions,
    adapter: cache.adapter,
  });

  axiosApi.interceptors.response.use(undefined, (error) => {
    if (error.response?.status === 401) {
      localStorage.removeItem("jwt");
      if (window.location.pathname !== "/login") {
        window.location.href = `/login?redirect=${window.location.href}`;
      }
    }
    return Promise.reject(error);
  });

  return axiosApi;
};

export { API };
