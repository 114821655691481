import { API } from "@/utils/api";
import { InsightLayout, InsightWidget } from "@/types";

export const getInsightsLayout = async (projectId = "") => {
  const response = await API().get(`/insights_layout/${projectId}`);
  return response.data;
};

export const updateInsightsLayout = async (
  projectId = "",
  data: InsightLayout,
) => {
  const response = await API().put(`/insights_layout/${projectId}`, data);
  return response.data;
};

export const addInsightsWidget = async (
  projectId = "",
  data: InsightWidget,
) => {
  const response = await API().post(
    `/insights_layout/${projectId}/widget`,
    data,
  );
  return response.data;
};
export const updateInsightsWidget = async (
  projectId = "",
  widgetId = "",
  data: InsightWidget,
) => {
  const response = await API().put(
    `/insights_layout/${projectId}/widget/${widgetId}`,
    data,
  );
  return response.data;
};

export const deleteInsightsWidget = async (projectId = "", widgetId = "") => {
  const response = await API().delete(
    `/insights_layout/${projectId}/widget/${widgetId}`,
  );
  return response.data;
};
