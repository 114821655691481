import classNames from "classnames";
import { capitalize } from "lodash";
import "./style.scss";

interface Props {
  title: string;
  description: string;
  active?: boolean;
  onClick(): void;
}

export const SelectItem = ({ title, description, active, onClick }: Props) => {
  return (
    <div
      className={classNames("new-figure-modal__select-item", { active })}
      onClick={onClick}
    >
      <div className="new-figure-modal__select-item__title">
        {capitalize(title)}
      </div>
      <div className="new-figure-modal__select-item__description">
        {description}
      </div>
    </div>
  );
};
