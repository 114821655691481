import React, { useEffect, useContext, createContext, useState } from "react";
import { useLocalstorageState } from "rooks";
import {
  InsightToken,
  InsightTopSigner,
  PayloadInvariants,
  MaxTokenTransfers,
  Project,
  Bot,
  Alert,
  Address,
  InsightWidget,
  InsightMetric,
} from "@/types";
import { useSearchParams } from "react-router-dom";
import { getUnreadAlertCount } from "@/api";
import { useQuery } from "react-query";

export interface MonitorState {
  data: { [key: string]: InsightToken[] };
  topSigners: {
    [key: string]: InsightTopSigner[];
  };
  setToken(addr: string, param: InsightToken[]): void;
  addToken(addr: string, param: InsightToken[]): void;
  setTokenThreshold(addr: string, token: string, threshold: number): void;
  setTopSigners(param: { [key: string]: InsightTopSigner[] }): void;
  currentAddress?: Address;
  setCurrentAddress(addr?: Address): void;
  currentProjectId: string;
  setCurrentProjectId(projectId: string): void;
  currentProject: Project | null;
  setCurrentProject(project?: Project): void;
  projects: Project[];
  currentBotId: string;
  setCurrentBotId(botId: string): void;
  currentAlertId: string;
  setCurrentAlertId(alertId: string): void;
  currentAddressId: string;
  setCurrentAddressId(addressId: string): void;
  getCurrentTopSigners(): InsightTopSigner[] | undefined | null | void;
  updateTopSignerData(data: InsightTopSigner[]): void;
  updateTopSignerValue(signer: string, value: number): void;
  currentTopSigners: InsightTopSigner[];
  currentData: InsightToken[];
  invariantsData: {
    [key: string]: {
      payloadInvariants: PayloadInvariants[];
      maxTokenTransfers: MaxTokenTransfers[];
      externalDependencies: any[];
      cohortPrograms: any[];
    };
  };
  currentInvariants: {
    payloadInvariants: PayloadInvariants[];
    maxTokenTransfers: MaxTokenTransfers[];
    externalDependencies: any[];
    cohortPrograms: any[];
  };
  updateCurrentInvariants(data: any): void;
  isProjectLoading: boolean;
  setIsProjectLoading(loading: boolean): void;
  setProjects(projects: Project[]): void;
  botsData: Bot[];
  setBotsData(bots: Bot[]): void;
  isLoadingBotsData: boolean;
  alertsData: Alert[];
  setAlertsData(alerts: Alert[]): void;
  refetchNewAlertCount(): void;
  currentView: "bots" | "alerts" | "insights";
  setCurrentView(view: "bots" | "alerts" | "insights"): void;
  currentMobileView: "bots" | "project";
  setCurrentMobileView(view: "bots" | "project"): void;
  newAlertCount: number;
  currentInsightsLayout: InsightWidget[];
  setCurrentInsightsLayout(widgets: InsightWidget[]): void;
  metricData: InsightMetric[];
  setMetricData(param: InsightMetric[]): void;
  alertFrom: string;
  setAlertFrom(data: string): void;
  alertTo: string;
  setAlertTo(data: string): void;
  showOnlyAlertUnread: boolean;
  setShowOnlyAlertUnread(data: boolean): void;
}

const initialState: MonitorState = {
  data: {},
  topSigners: {},
  setToken: () => {},
  addToken: () => {},
  setTokenThreshold: () => {},
  setTopSigners: () => {},
  setCurrentAddress: () => {},
  updateTopSignerData: () => {},
  getCurrentTopSigners: () => {},
  updateTopSignerValue: () => {},
  currentAddress: undefined,
  currentTopSigners: [],
  currentData: [],
  projects: [],
  invariantsData: {},
  currentInvariants: {
    payloadInvariants: [],
    maxTokenTransfers: [],
    externalDependencies: [],
    cohortPrograms: [],
  },
  updateCurrentInvariants: () => {},
  currentProjectId: "",
  setCurrentProjectId: () => {},
  currentBotId: "",
  setCurrentBotId: () => {},
  currentAlertId: "",
  setCurrentAlertId: () => {},
  currentAddressId: "",
  setCurrentAddressId: () => {},
  currentProject: null,
  setCurrentProject: () => {},
  setProjects: () => {},
  isLoadingBotsData: false,
  alertsData: [],
  setAlertsData: () => {},
  botsData: [],
  setBotsData: () => {},
  currentView: "bots",
  setCurrentView: () => {},
  currentMobileView: "project",
  setCurrentMobileView: () => {},
  isProjectLoading: false,
  setIsProjectLoading: () => {},
  refetchNewAlertCount: () => {},
  newAlertCount: 0,
  currentInsightsLayout: [],
  setCurrentInsightsLayout: () => {},
  metricData: [],
  setMetricData: () => {},
  alertFrom: "",
  setAlertFrom: () => {},
  alertTo: "",
  setAlertTo: () => {},
  showOnlyAlertUnread: false,
  setShowOnlyAlertUnread: () => {},
};

export const MonitorContext = createContext<MonitorState>(initialState);

export const useMonitor = () => useContext(MonitorContext);

const STORAGE_KEY = "sec3-monitor";

interface Props {
  children: React.ReactNode;
}

export const MonitorProvider = ({ children }: Props) => {
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);
  const [currentProject, saveCurrentProject] =
    useLocalstorageState<Project | null>("sec3-current-project", null);
  const [currentAddress, setCurAddress] = useState<Address>();
  const [currentInsightsLayout, setCurrentInsightsLayout] = useState<
    InsightWidget[]
  >([]);
  const [currentAddressId, setCurAddressId] = useState("");
  const [newAlertCount, setNewAlertCount] = useState(0);
  const [currentBotId, setCurBotId] = useState("");
  const [currentAlertId, setCurAlertId] = useState("");
  const [currentProjectId, setCurProjectId] = useState("");
  const [botsData, setBotsData] = useState<Bot[]>([]);
  const [metricData, setMetricData] = useState<InsightMetric[]>([]);
  const [alertsData, setAlertsData] = useState<Alert[]>([]);
  const [projects, setProjects] = useState<Project[]>([]);
  const [isProjectLoading, setIsProjectLoading] = useState(false);
  const [isLoadingBotsData, setIsLoadingBotsData] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentView, setCurrentView] = useState<
    "bots" | "alerts" | "insights"
  >("bots");
  const [currentMobileView, setCurrentMobileView] = useState<
    "bots" | "project"
  >("project");
  const [alertFrom, setAlertFrom] = useState("");
  const [alertTo, setAlertTo] = useState("");
  const [showOnlyAlertUnread, setShowOnlyAlertUnread] = useState(false);

  useEffect(() => {
    set(value);
    return () => {};
  }, [value, set]);

  const [data, setData] = useState<{ [key: string]: InsightToken[] }>({});
  const [topSigners, setTopSigners] = useState<{
    [key: string]: InsightTopSigner[];
  }>({});
  const [invariantsData, setInvariantsData] = useState<{
    [key: string]: {
      payloadInvariants: PayloadInvariants[];
      maxTokenTransfers: MaxTokenTransfers[];
      externalDependencies: any[];
      cohortPrograms: any[];
    };
  }>({});

  const updateCurrentInvariants = (data: any) => {
    setInvariantsData({
      ...invariantsData,
      [currentAddress!.id]: {
        ...invariantsData[currentAddress!.id],
        ...data,
      },
    });
  };

  const updateTopSignerData = (data: InsightTopSigner[]) => {
    if (currentAddress!.id) {
      setTopSigners({
        ...topSigners,
        [currentAddress!.id]: data,
      });
    }
  };

  const updateTopSignerValue = (signer: string, value: number) => {
    if (currentAddress!.id) {
      setTopSigners({
        ...topSigners,
        [currentAddress!.id]: topSigners[currentAddress!.id].map((item) => {
          if (item.signer === signer) {
            return { ...item, value };
          }
          return item;
        }),
      });
    }
  };

  const getCurrentTopSigners = () => {
    return topSigners[currentAddress!.id];
  };

  const setToken = (addr: string, param: InsightToken[]) => {
    setData({
      ...data,
      [addr]: param,
    });
  };
  const addToken = (addr: string, param: InsightToken[]) => {
    setData((data) => ({
      ...data,
      [addr]: [...(data[addr] || []), ...param],
    }));
  };
  const setTokenThreshold = (
    addr: string,
    token: string,
    threshold: number,
  ) => {
    setData((data) => ({
      ...data,
      [addr]: data[addr].map((item) => {
        if (item.token === token) {
          return { ...item, threshold };
        }
        return item;
      }),
    }));
  };

  useEffect(() => {
    if (currentAddress) {
      setIsLoadingBotsData(true);

      setIsLoadingBotsData(false);
    } else if (currentProjectId) {
      setIsLoadingBotsData(true);

      setIsLoadingBotsData(false);
    }
    return () => {};
  }, [currentAddress, currentProjectId]);

  const setCurrentBotId = (botId: string) => {
    setCurBotId(botId);
    searchParams.set("botId", botId);
    setSearchParams(searchParams);
  };
  const setCurrentAlertId = (alertId: string) => {
    setCurAlertId(alertId);
    searchParams.set("alertId", alertId);
    setSearchParams(searchParams);
  };
  const setCurrentProjectId = (projId: string) => {
    setCurProjectId(projId);
    searchParams.set("projectId", projId);
    setSearchParams(searchParams);
  };
  const setCurrentAddressId = (addrId: string) => {
    setCurAddressId(addrId);
    searchParams.set("addressId", addrId);
    setSearchParams(searchParams);
  };
  const setCurrentAddress = (addr: Address) => {
    setCurAddress(addr);
    setCurrentAddressId(addr?.id || "");
  };

  const { refetch: refetchNewAlertCount } = useQuery<{ count: number }, Error>(
    ["new alerts count", currentAddressId, currentProjectId, currentView],
    () =>
      currentAddressId
        ? getUnreadAlertCount(currentAddressId, "addressId")
        : currentProjectId
          ? getUnreadAlertCount(currentProjectId, "projectId")
          : Promise.resolve(0),
    {
      keepPreviousData: true,
      initialData: { count: 0 },
      enabled: !!currentProjectId,
      onSuccess: (data) => {
        setNewAlertCount(data.count);
      },
    },
  );

  return (
    <MonitorContext.Provider
      value={{
        data,
        setToken,
        addToken,
        setTokenThreshold,
        setTopSigners,
        topSigners,
        currentAddress,
        setCurrentAddress,
        currentProjectId,
        setCurrentProjectId,
        updateTopSignerData,
        getCurrentTopSigners,
        currentTopSigners: currentAddress ? topSigners[currentAddress!.id] : [],
        currentData: currentAddress ? data[currentAddress!.id] : [],
        updateTopSignerValue,
        invariantsData,
        currentInvariants: currentAddress
          ? invariantsData[currentAddress!.id]
          : {
              payloadInvariants: [],
              maxTokenTransfers: [],
              externalDependencies: [],
              cohortPrograms: [],
            },
        updateCurrentInvariants,
        currentBotId,
        setCurrentBotId,
        currentProject,
        setCurrentProject: (e: Project) => saveCurrentProject(e),
        projects,
        setProjects,
        botsData,
        setBotsData,
        isLoadingBotsData,
        alertsData,
        setAlertsData,
        currentView,
        setCurrentView,
        isProjectLoading,
        setIsProjectLoading,
        currentAddressId,
        setCurrentAddressId,
        currentMobileView,
        setCurrentMobileView,
        refetchNewAlertCount,
        newAlertCount,
        currentInsightsLayout,
        setCurrentInsightsLayout,
        metricData,
        setMetricData,
        currentAlertId,
        setCurrentAlertId,
        alertFrom,
        alertTo,
        setAlertFrom,
        setAlertTo,
        showOnlyAlertUnread,
        setShowOnlyAlertUnread,
      }}
    >
      {children}
    </MonitorContext.Provider>
  );
};
