import { deleteUser } from "@/api";
import { Button, FormInput, Modal } from "@/components";
import { useAuth } from "@/contexts";
import { Form, Formik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import "./style.scss";

const validationSchema = Yup.object().shape({
  reason: Yup.string().required("Reason is required"),
  password: Yup.string()
    .min(8, "Must be at least 8 characters")
    .required("Password is required"),
});

interface Props {
  open: boolean;
  setOpen(param: boolean): void;
  onCreate?(params: any): void;
}
export const DeleteAccountModal = ({ open, setOpen }: Props) => {
  let reset = () => {};
  const [error, setError] = useState("");
  const { logout, userInfo } = useAuth();
  return (
    <Modal
      title={"Delete Account"}
      open={open}
      setOpen={(param) => {
        setOpen(param);
        reset();
      }}
    >
      <Formik
        initialValues={{
          reason: "",
          password: "",
        }}
        validationSchema={validationSchema}
        onSubmit={async (values) => {
          setError("");
          try {
            await deleteUser(userInfo?.id || "", values);
            logout();
          } catch (error: any) {
            setError(error.response.data.message);
          }
        }}
      >
        {({ isSubmitting, resetForm }) => {
          reset = resetForm;
          return (
            <Form>
              <div className="new-test-modal">
                <div className="row">
                  <div className="my-auto col-12 col-md-6">Reason</div>
                  <FormInput
                    className="mt-1 mt-sm-0 col-12 col-md-6"
                    name="reason"
                  />
                </div>

                <div className="line my-4"></div>
                <div className="row">
                  <div className="my-auto col-12 col-md-6">Enter password</div>
                  <FormInput
                    className="mt-1 mt-sm-0 col-12 col-md-6"
                    name="password"
                    type="password"
                  />
                </div>

                {error && (
                  <div className="text-right mt-3 color-red">{error}</div>
                )}
                <div className="d-flex mt-4">
                  <Button onClick={() => setOpen(false)}>Close</Button>
                  <Button
                    className="ms-auto"
                    color="secondary"
                    isLoading={isSubmitting}
                    type="submit"
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};
