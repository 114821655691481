/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import {
  getInsightsLayout,
  getInsightsMetrics,
  updateInsightsLayout,
} from "@/api";
import { Figure } from "@/components";
import { useMonitor } from "@/contexts";
import { useModal } from "@/hooks";
import { NewFigureModal } from "@/modals";
import { InsightLayout, InsightMetric, InsightWidget } from "@/types";
import { useState } from "react";
import RGL, { Responsive, WidthProvider } from "react-grid-layout";
import "react-grid-layout/css/styles.css";
import { useMutation, useQuery } from "react-query";
import "react-resizable/css/styles.css";
import "./style.scss";
const ResponsiveReactGridLayout = WidthProvider(Responsive);

export const FlexDashboard = () => {
  const {
    currentProjectId,
    setCurrentInsightsLayout,
    currentInsightsLayout,
    setMetricData,
  } = useMonitor();
  const editModal = useModal();
  const [editWdiget, setEditWdiget] = useState<InsightWidget>();

  useQuery<InsightMetric[], Error>(
    ["insight_metrics"],
    () => getInsightsMetrics(),
    {
      keepPreviousData: true,
      onError: () => {},
      initialData: [],
      onSuccess: (res) => {
        setMetricData(
          res.map((metric: any) => ({
            ...metric,
            predefinedQueries: metric.predefinedQueries.map((query: any) => ({
              ...query,
              chartType: query.chart,
              groupBy: query.categorizedBy,
            })),
          })),
        );
      },
    },
  );

  const { isLoading, isError, error, isFetching } = useQuery<
    { message: string; code: number; data: InsightLayout },
    Error
  >(
    ["insightsLayout", currentProjectId],
    () => getInsightsLayout(currentProjectId),
    {
      keepPreviousData: true,
      enabled: !!currentProjectId,
      onSuccess: async (res) => {
        if (!res.data) {
          await updateInsightsLayout(currentProjectId, {
            projectId: currentProjectId,
            widgets: [],
          });
        } else {
          setCurrentInsightsLayout(res.data.widgets);
        }
      },
    },
  );
  const { mutate: updateLayout } = useMutation(
    (widgets: InsightWidget[]) =>
      updateInsightsLayout(currentProjectId, {
        projectId: currentProjectId,
        widgets,
      }),
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );

  const handleLayoutChange = (
    _layout: RGL.Layout[],
    allLayouts: RGL.Layouts,
  ) => {
    const updatedLayout = currentInsightsLayout.map((widget: InsightWidget) => {
      const pos = allLayouts.lg.find((w) => w.i === widget.id);
      return {
        ...widget,
        x: pos?.x!,
        y: pos?.y!,
        width: pos?.w!,
        height: pos?.h!,
      };
    });
    setCurrentInsightsLayout(updatedLayout);
    updateLayout(updatedLayout);
  };

  const handleEditFigure = (widget: InsightWidget) => {
    setEditWdiget(widget);
    editModal.setOpen(true);
  };
  if (isLoading || isFetching)
    return <div className="insights-layout__loading">Loading...</div>;
  if (isError) return <div>{error.toString()}</div>;
  return (
    <>
      {/* <div className="d-flex mt-4 me-4">
        <Button className="ms-auto">New Chart</Button>
      </div> */}
      <ResponsiveReactGridLayout
        onLayoutChange={handleLayoutChange}
        layouts={{
          lg: currentInsightsLayout.map((widget) => ({
            i: widget.id?.toString()!,
            w: widget.width,
            h: widget.height,
            x: widget.x,
            y: widget.y,
          })),
        }}
        className="insights-layout"
        rowHeight={120}
        cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        isDraggable
        isResizable
        draggableHandle=".insights-figure__header-title"
      >
        {currentInsightsLayout.map((widget) => (
          <div key={widget.id}>
            <Figure
              data={widget}
              onEdit={(widget) => handleEditFigure(widget)}
            />
          </div>
        ))}
      </ResponsiveReactGridLayout>
      <NewFigureModal {...editModal} data={editWdiget} />
    </>
  );
};
