/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useContext, createContext, useReducer } from "react";
import { LoginPayload, User } from "@/types";
import AuthReducer from "./AuthReducer";
import { useLocalstorageState } from "rooks";
import { LOGIN, LOGOUT, UPDATE_USER_INFO } from "./../types";
import { useNavigate } from "react-router-dom";
import { useWebSocket } from "@/hooks/useWebSocket";
import { getUser } from "@/api";
declare const window: any;

export interface AuthState {
  userInfo: User | null;
  isLoggedIn: boolean;
  login(params: LoginPayload): void;
  logout(): void;
  githubLogin(code: String): void;
  updateUserInfo(params: Partial<User>): void;
}

const initialState: AuthState = {
  isLoggedIn: false,
  userInfo: null,
  login: () => {},
  logout: () => {},
  githubLogin: () => {},
  updateUserInfo: () => {},
};

export const AuthContext = createContext<AuthState>(initialState);

export const useAuth = () => useContext(AuthContext);

const STORAGE_KEY = "sec3-user-state";

interface Props {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: Props) => {
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);
  const [state, dispatch] = useReducer(AuthReducer, value);
  const { connectWebSocket, disconnectWebSocket } = useWebSocket();
  const navigate = useNavigate();
  useEffect(() => {
    set(state);
    return () => {};
  }, [state, set]);

  useEffect(() => {
    // Always refresh when context is initialized
    refreshUserInfo();
  }, []);

  const login = async (payload: User) => {
    dispatch({ type: LOGIN, payload });
    connectWebSocket();
  };

  const githubLogin = async (code: string) => {
    dispatch({ type: LOGIN, payload: { code } });
    connectWebSocket();
  };

  const updateUserInfo = async (payload: User) => {
    dispatch({ type: UPDATE_USER_INFO, payload });
  };

  const logout = () => {
    window.localStorage.removeItem("jwt");
    dispatch({ type: LOGOUT });
    navigate("/");
    disconnectWebSocket();
  };

  const refreshUserInfo = async () => {
    // Only when the token exists does the client fetch user
    if (localStorage.getItem("jwt")) {
      const user = await getUser();
      await updateUserInfo(user);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        githubLogin,
        updateUserInfo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
