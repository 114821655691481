/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { FigureGraph, Modal } from "@/components";
import { InsightWidget } from "@/types";
import "./style.scss";

interface Props {
  open: boolean;
  setOpen(param: boolean): void;
  title: string;
  data: InsightWidget;
}

export const FullFigureModal = ({ open, setOpen, title, data }: Props) => {
  const reset = () => {};

  return (
    <Modal
      title={title}
      icon="address"
      className="full-figure-modal-container"
      open={open}
      setOpen={(param) => {
        setOpen(param);
        reset();
      }}
    >
      <div className="full-figure-modal">
        <div className="full-figure-modal__content">
          <FigureGraph
            metric={data.queries[0].metric}
            queryName={data.queries[0].queryName}
            address={data.queries[0].addressId}
            property={data.queries[0].property}
            chartType={data.queries[0].chartType}
            timeRange={data.queries[0].timeRange}
            interval={data.queries[0].interval}
            aggregate={data.queries[0].aggregate}
            groupBy={data.queries[0].groupBy}
            textColor={data.textColor}
            backgroundColor={data.backgroundColor}
            isFullScreen
          />
        </div>
      </div>
    </Modal>
  );
};
