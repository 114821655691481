import { Address } from "@/types";
import { API } from "@/utils/api";

export const getAllProjects = async () => {
  const response = await API().get(`/projects`);
  return response.data.data.projects;
};

export const getOneProjectById = async (projectId: string) => {
  if (projectId) {
    const response = await API().get(`/project/${projectId}`);
    return response.data.data.project;
  }
  return {};
};

export const createProject = async (payload: { name: string }) => {
  const response = await API().post(`/project`, payload);
  return response.data.data.project;
};

export const updateProjectNameById = async (payload: {
  projectId: string;
  name: string;
}) => {
  const response = await API().put(`/project/${payload.projectId}`, payload);
  return response.data.data.project;
};

export const deleteProject = async (projectId: string) => {
  const response = await API().delete(`/project/${projectId}`);
  return response.data;
};

export const getAddressById = async (addressId: string) => {
  const response = await API().get(`/address/${addressId}`);
  return response.data.data.address;
};

export const addAddressToProject = async (
  projectId: string,
  address: string,
  alias: string,
  chain: string,
  network: string,
) => {
  const response = await API().post(`/address`, {
    projectId,
    address,
    alias,
    chain,
    network,
  });
  return response.data.data.address;
};

export const deleteAddressById = async (addressId: string) => {
  const response = await API().delete(`/address/${addressId}`);
  return response.data;
};

export const updateAddressById = async (address: Address) => {
  const response = await API().put(`/address/${address.id}`, {
    alias: address.alias,
    chain: address.chain,
    network: address.network,
    address: address.address,
  });
  return response.data.data.address;
};

export const getAddressTokenAccount = async (id: string) => {
  const response = await API().get(`/address/${id}/tokenAccounts`);
  return response.data.data;
};
