/* eslint-disable react-hooks/exhaustive-deps */
import BarLoader from "react-spinners/BarLoader";
import sec3Logo from "@/assets/logo2.png";
import "./style.scss";

export const LoadingPage = () => {
  return (
    <div className="loading-page">
      <div className="loading-page__content">
        <div className="loading-page__text">
          <img src={sec3Logo} />
          Loading...
        </div>
        <BarLoader color="#2e49bc" width={"100%"} />
      </div>
    </div>
  );
};
