import classNames from "classnames";
import { SelectItem } from "./../SelectItem";

interface Props {
  data?: {
    name: string;
    description: string;
    id: string;
  }[];
  value?: string;
  onChange?: (value: any) => void;
  className?: string;
}

export const SelectZone = ({
  data = [],
  value,
  onChange = () => {},
  className,
}: Props) => {
  return (
    <div className={classNames("d-flex flex-wrap w-100", className)}>
      {data.map((item) => (
        <SelectItem
          key={item.id}
          title={item.name}
          description={item.description}
          active={item.id === value}
          onClick={() => onChange(item.id)}
        />
      ))}
    </div>
  );
};
