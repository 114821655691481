/* eslint-disable react-hooks/exhaustive-deps */
import { createProject } from "@/api";
import { Button, FormInput, Modal } from "@/components";
import { useInput } from "@/hooks";
import { Project } from "@/types";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as Yup from "yup";
import "./style.scss";
interface Props {
  open: boolean;
  setOpen(param: boolean): void;
  onCreate?(project: Project): void;
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Project Name is required"),
});

export const NewProjectModal = ({
  open,
  setOpen,
  onCreate = () => {},
}: Props) => {
  const reset = () => {};
  const name = useInput("");
  const [error, setError] = useState("");
  const { mutateAsync: createNewProject } = useMutation(createProject, {
    onSuccess: (data) => {
      toast.success(`Project ${name.value} is created!`);
      setOpen(false);
      onCreate(data);
    },
    onError: (err: any) => {
      setError(err.response.data.message);
    },
  });

  useEffect(() => {
    name.setValue("");
    return () => {};
  }, [open]);

  return (
    <Modal
      title={"New Project"}
      className="new-project-modal"
      open={open}
      setOpen={(param) => {
        setOpen(param);
        reset();
      }}
    >
      <div className="new-test-modal">
        <Formik
          initialValues={{
            name: "",
          }}
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            try {
              await createNewProject({
                name: values.name,
              });
            } catch (error: any) {
              setError(error.response.data.message);
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form>
                <div className="mb-4 mt-1">
                  <div className="d-flex flex-column flex-sm-row">
                    <div className="flex-1 d-flex">
                      <div className="new-test-modal__heading my-auto">
                        Please enter a name for your project
                      </div>
                    </div>
                    <FormInput
                      className="ms-0 ms-sm-auto mt-2 mt-sm-0 flex-1"
                      name="name"
                      autoFocus
                    />
                  </div>
                </div>
                {error && (
                  <div className="text-center mt-3 color-red">{error}</div>
                )}
                <div className="d-flex mt-3">
                  <Button
                    type="submit"
                    className="ms-auto"
                    isLoading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Create Project
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};
