import { ALERTS_PAGE_SIZE } from "@/config";
import { API } from "@/utils/api";
import { stringify } from "query-string";

interface GetAllAlertsProps {
  objectId?: string;
  queryBy?: string;
  sortBy?: string;
  sortOrder?: number;
  severityFilter?: string;
  showArchived?: boolean;
  showUnreadOnly?: boolean;
  offset?: number;
  size?: number;
  from?: number;
  to?: number;
}

export const getAllAlerts = async ({
  objectId = "",
  queryBy = "userId", // userId, botId, projectId, addressId
  sortBy = "time", // time, type, severity
  sortOrder = -1, // 1 for ascending, -1 for descending
  severityFilter = "", // low, medium, high, critical
  showArchived = false,
  showUnreadOnly = false,
  offset = 0,
  size = ALERTS_PAGE_SIZE,
  from = undefined,
  to = undefined,
}: GetAllAlertsProps) => {
  const response = await API().get(
    `/alerts?${stringify({
      id: objectId,
      queryBy,
      sortBy,
      sortOrder,
      severityFilter,
      showArchived,
      showUnreadOnly,
      offset,
      size,
      from,
      to,
    })}`,
  );
  return response.data.data;
};

export const getLeanAlerts = async ({
  objectId = "",
  queryBy = "", // userId, botId, projectId, addressId
  severityFilter = "", // low, medium, high, critical
  showArchived = false,
  showUnreadOnly = false,
  from = undefined,
  to = undefined,
}: GetAllAlertsProps) => {
  const response = await API().get(
    `/lean_alerts?${stringify({
      id: objectId,
      queryBy,
      severityFilter,
      showArchived,
      showUnreadOnly,
      from,
      to,
    })}`,
  );
  return response.data.data;
};

export const getUnreadAlertCount = async (
  objectId = "",
  queryBy = "userId", // userId, botId, projectId, addressId
) => {
  const response = await API().get(
    `/alerts_unread_count?id=${objectId}&queryBy=${queryBy}`,
  );
  return response.data.data;
};

export const getAlertStats = async (
  objectId = "",
  queryBy = "userId", // userId, botId, projectId, addressId
  typeFilter = "", // SmartThreatMonitor, AbnormalTransferSol, ....
  severityFilter = "", // low, medium, high, critical
  showArchived: boolean = false,
  from: number = 0,
  to: number = 0,
  countBy: string = "type", // type, severity, time
) => {
  const response = await API().get(
    `/alert_stats?id=${objectId}&queryBy=${queryBy}&showarchived=${showArchived}&typeFilter=${typeFilter}&severityFilter=${severityFilter}&from=${from}&to=${to}&countBy=${countBy}`,
  );
  return response.data.data;
};

export const getOneAlert = async (alertId: string = "") => {
  const response = await API().get(`/alert/${alertId}`);
  return response.data.data.alert;
};

/* Archive an alert */
export const archiveAlertById = async (
  alertId: string,
  isArchived: boolean,
) => {
  const response = await API().put(`/alert/${alertId}/_archive`, {
    isArchived,
  });
  return response.data.data;
};

/* Read an alert */
export const readAlertById = async (alertId: string, isRead: boolean) => {
  const response = await API().put(`/alert/${alertId}/_read`, {
    isRead,
  });
  return response.data.data;
};

/* Read alerts */
export const readAlerts = async (alertIds: string[], isRead: boolean) => {
  const response = await API().put(`/alerts/_read`, {
    alertIds,
    isRead,
  });
  return response.data.data;
};

/* Archive alerts */
export const archiveAlerts = async (
  alertIds: string[],
  isArchived: boolean,
) => {
  const response = await API().put(`/alerts/_archive`, {
    alertIds,
    isArchived,
  });
  return response.data.data;
};
