import React, { useContext, createContext } from "react";
import { useLocalstorageState } from "rooks";
import { Bot, Project, Address } from "@/types";
import { uniqBy, remove } from "lodash";
export interface AlertsState {
  projects: {
    id: string;
    name: string;
    bots: Bot[];
    botCount: number;
    status: string;
    addresses: Address[];
  }[];
  addBots: (projectId: string, bots: Bot[]) => void;
  setProjectBotCount: (projectId: string, botCount: number) => void;
  updateBot: (projectId: string, botId: string, bot: Bot) => void;
  deleteBot: (projectId: string, botId: string) => void;
  setProjects: (project: Project[]) => void;
  currentProjectId: string;
  setCurrentProjectId: (id: string) => void;
}

const initialState: AlertsState = {
  projects: [],
  addBots: () => {},
  setProjects: () => {},
  currentProjectId: "",
  setCurrentProjectId: () => {},
  setProjectBotCount: () => {},
  updateBot: () => {},
  deleteBot: () => {},
};

export const AlertsContext = createContext<AlertsState>(initialState);

export const useAlerts = () => useContext(AlertsContext);

const STORAGE_KEY = "sec3-alertsstate";

interface Props {
  children: React.ReactNode;
}

export const AlertsProvider = ({ children }: Props) => {
  const [value, set] = useLocalstorageState(STORAGE_KEY, initialState);

  const addBots = (projectId: string, bots: Bot[]) => {
    set((state) => {
      let tempState = { ...state };
      const indexOfProject = state.projects.findIndex(
        (project) => project.id === projectId,
      );
      if (indexOfProject >= 0 && tempState.projects[indexOfProject]) {
        tempState.projects[indexOfProject].bots = uniqBy(
          [...(state.projects[indexOfProject].bots || []), ...bots],
          "id",
        );
      }
      return { ...tempState };
    });
  };

  const setProjectBotCount = (projectId: string, botCount: number) => {
    set((state) => {
      let tempState = { ...state };
      const indexOfProject = state.projects.findIndex(
        (project) => project.id === projectId,
      );
      tempState.projects[indexOfProject].botCount = botCount;
      return { ...tempState };
    });
  };

  const updateBot = (projectId: string, botId: string, bot: Bot) => {
    set((state) => {
      const indexOfProject = state.projects.findIndex(
        (project) => project.id === projectId,
      );
      const indexOfBot = state.projects[indexOfProject].bots.findIndex(
        (bot) => bot.id === botId,
      );
      state.projects[indexOfProject].bots[indexOfBot] = bot;
      return { ...state };
    });
  };

  const setProjects = (projects: Project[]) => {
    set((state) => {
      state.projects = projects.map((proj) => ({
        id: proj.id,
        name: proj.name,
        addresses: proj.addresses || [],
        status: "init",
        bots: [],
        botCount: 0,
      }));
      return { ...state };
    });
  };

  const deleteBot = (projectId: string, botId: string) => {
    set((state) => {
      const indexOfProject = state.projects.findIndex(
        (project) => project.id === projectId,
      );
      state.projects[indexOfProject].bots = remove(
        state.projects[indexOfProject].bots,
        (bot) => bot.id !== botId,
      );
      state.projects[indexOfProject].botCount =
        state.projects[indexOfProject].botCount - 1;
      return { ...state };
    });
  };

  return (
    <AlertsContext.Provider
      value={{
        projects: value.projects,
        addBots,
        setProjects,
        currentProjectId: value.currentProjectId,
        setProjectBotCount,
        updateBot,
        deleteBot,
        setCurrentProjectId: (id) =>
          set((state) => ({ ...state, currentProjectId: id })),
      }}
    >
      {children}
    </AlertsContext.Provider>
  );
};
