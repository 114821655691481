import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";
import styled from "styled-components";

interface Props {
  onClick?(): void;
  status?: -1 | 1 | 0;
  className?: string;
}
export const TableSortIcon = ({
  onClick = () => {},
  status,
  className,
}: Props) => {
  return (
    <Container onClick={onClick} className={className}>
      {status === 0 ? (
        <FaSort size={12} color="#bbb" />
      ) : status === 1 ? (
        // 1 for ascending
        <FaSortUp size={12} color="#111843" />
      ) : (
        // -1 for descending
        <FaSortDown size={12} color="#111843" />
      )}
    </Container>
  );
};
const Container = styled.span`
  height: fit-content;
  display: flex;
`;
